import { useState, useRef, useEffect } from "react";
import logo_img from "./img/proion_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";

import About from "./About";
import { changeVisibility } from "./Form";

const Nav = ({ services, setServices, AOI }) => {
  const [aboutModalState, setAboutModalState] = useState(false);
  const [content, setContent] = useState("Data Search")

  useEffect(() => {
    setContent(AOI)
  }, [AOI])
  
  return (
    <>
      <div className='navbar'>
        <a href='/'>
          <img src={logo_img} className='nav-logo' />
        </a>
        <div className='navbar-btns'>
          <button
            className='navbar-about-btn'
            id='Search'
            // disabled={services}
            onClick={() => {
              changeVisibility(true);
              setServices(true);
            }}

            onMouseEnter={() => setContent("Data Search")}
            onMouseLeave={() => setContent(AOI)}
          >
            {AOI ? (
              <>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> <span id="search-btn-content">{content}</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> <span id="search-btn-content">Data Search</span>
              </>
            )}
          </button>
          {/* <button onClick={e => displayTLS()}>
            TLS
          </button> */}
          <button
            className='navbar-about-btn'
            id='About'
            onClick={() => setAboutModalState(!aboutModalState)}
          >
            About
          </button>
        </div>
      </div>
      {aboutModalState && <About setAboutModalState={setAboutModalState} />}
    </>
  );
};

export { Nav };
