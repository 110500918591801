//Libraries
import { useRef, useEffect, useState } from "react";
import * as turf from "@turf/turf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Icons
import {
  faChartLine,
  faCube,
  faCubes,
  faDrawPolygon,
  faLayerGroup,
  faMap,
  faX,
} from "@fortawesome/free-solid-svg-icons";

//External functions
import { globalMap, pointcloudsCapabilities } from "./Map";
import { getPointCloudReq } from "./Requests.js";
import { displayTLS } from "./Map";
import { setLoadingBarPercentage } from "./App";
import { getAvReq, getLocalDataReq, getVideoReq } from "./Requests.js";

//Images
import darkMap from "./img/basemaps/dark.png";
import lightMap from "./img/basemaps/light.png";
import sateMap from "./img/basemaps/satellite.png";
import streetMap from "./img/basemaps/streets.png";
import insar_color_ramp from "./img/legend/insar_color_ramp_2.png";
import rss_label from "./img/legend/rss.png";
import geology_label from "./img/legend/geology_label.png";
import reflectors_markers from "./img/markers/reflectore_marker.png";
import site_markers from "./img/markers/site_marker.png";
import meteo_markers from "./img/markers/meteo_marker.png";

//Data
import data from "./data/geodep_los_test_project_4326_joined.json";
//Point Clouds images
import Geodep_PC_thumbnail from "./img/legend/geodep.png";
import Krini_PC_thumbnail from "./img/legend/krini.png";
import Asteri_PC_thumbnail from "./img/legend/asteri.png";

//Components
import { MediaLoader } from "./Popups";
import { DataPlotChart } from "./Chart.js";
import objects from "threebox-plugin/src/objects/objects";

const polygon = {
  type: "geojson",
  data: {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [[]],
    },
  },
};

let ActiveAvTool = false;

const MapTools = ({
  mapStyle,
  changeMapStyle,
  lng,
  lat,
  pitch,
  bearing,
  zoom,
  setAvData,
  setNewAvData,
  sChart,
  setDisplayStationData,
  setavPolygon,
  layer_id,
  sitesC,
  meteoC,
  reflectorsC,
  setSitesC,
  setReflectorsC,
  setMeteoC,
  setGeodepC,
  setPointCloudC,
  geodepC,
  setfirst_LastDate,
  showChartBtn,
  setShowChart,
  setShowDataChart,
  showDataChart,
  RSSC,
  setRSSC,
  geologMap,
  setGeologMap,
  initRes,
  showDataTabTool,
  setLoadingReqPercentage,
  loadingReqPercentage,
  sendMeteoRequest,
  sendGeologRequest,
  AOI,
  loadedData,
}) => {
  const [firstRender, setFirstRender] = useState(false);
  const [vision, setVision] = useState(false);
  const [Dtooltip, setDtooltip] = useState("3D");
  const [avTool, setAvTool] = useState(false);
  const [showLegendTool, setShowLegendTool] = useState(false);
  const [avValues, setAvValues] = useState(null);
  const [cursorBox, setCursorBox] = useState({
    show: false,
    x: null,
    y: null,
    content: null,
  });
  // let globalActivatedDatasets = useRef({});
  const [globalActivatedDatasets, setGlobalActivatedDatasets] = useState({});

  //Point Cloud vars
  const pointclouds = useRef(null);
  const [pointCloudURL, setPointCloudURL] = useState(null);
  const [loadedPointcloud, setLoadedPointCloud] = useState({
    "Geology Dep.": false,
    Krini: false,
    "Asteri Dam": false,
  });
  const [pointCloudVideoStatus, setPointCloudVideoStatus] = useState({
    Geodep: false,
    Krini: false,
    Asteri: false,
  });
  const [videoURL, setVideoURL] = useState(null);
  const [videoURLStatus, setVideoURLStatus] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [loadingPC, setLoadingPC] = useState(null);

  const [dataChartData, setDataChartData] = useState([]);
  const [SARExistence, setSARExistence] = useState(false);

  // useEffect(() => {
  //   let exists = false;
  //   console.log(loadedData.current);
  //   if (loadedData.current === null || loadedData.current.length === 0) return
  //   for (let dataset of loadedData) {
  //     if ((dataset.properties.Dataset = "SAR_points")) {
  //       setSARExistence(true);
  //       exists = true;
  //       break;
  //     }
  //   }
  //   if (!exists) {
  //     setSARExistence(false);
  //   }
  // }, [loadedData]);

  useEffect(() => {
    if (showDataTabTool) {
      setShowLegendTool(true);
      setTimeout(() => {
        setShowLegendTool(false);
      }, 15000);
    }
  }, [showDataTabTool]);

  // Obtain Point Cloud Data
  useEffect(() => {
    async function sendPoinCloudRequest() {
      const response = await getPointCloudReq(
        pointCloudURL,
        setLoadingReqPercentage
      );
      pointclouds.current.features.forEach((pCloud) => {
        if (pCloud.properties.file === pointCloudURL) {
          alert(
            `3D Point Cloud of ${pCloud.properties.name} is loading ...\n\nThe interface might not response for a while. Please do not close the Tab before the point cloud is dislayed.`
          );

          displayTLS(response, pCloud.properties);
          mapFlyTo(pCloud.geometry.coordinates);
        }
      });
    }
    if (pointCloudURL !== null) {
      sendPoinCloudRequest();
    }
  }, [pointCloudURL]);

  useEffect(() => {
    async function sendRequest() {
      const response = await getLocalDataReq("pointcloud");
      pointclouds.current = response;
      pointcloudsCapabilities.length = 0;
      pointcloudsCapabilities.push(response);
    }
    sendRequest();
  }, []);

  const taggle2D3D = () => {
    if (!vision) {
      globalMap.setPitch(60);
      globalMap.rotateTo(30);
      setVision(!vision);
      setDtooltip("2D");
    } else {
      globalMap.setPitch(0);
      globalMap.rotateTo(0);
      setVision(!vision);
      setDtooltip("3D");
    }
  };

  function mapFlyTo(coords) {
    globalMap.flyTo({
      center: coords,
      zoom: 19,
      bearing: 140,
      pitch: 70,
      essentila: true,
    });
  }

  const chMapStyle = (id) => {
    zoom(globalMap.getZoom());
    let center = globalMap.getCenter();
    lng(center.lng);
    lat(center.lat);
    pitch(globalMap.getPitch());
    bearing(globalMap.getBearing());
    changeMapStyle(id);
  };

  const getFeatureWithin = (sourceGeoJSON, filterFeature) => {
    // Loop through all the features in the source geojson and return the ones that
    // are inside the filter feature (buffered radius) and are confirmed landing sites
    const featuresWithin = sourceGeoJSON.features.filter((feature) =>
      turf.booleanPointInPolygon(feature, filterFeature)
    );
    return featuresWithin;
  };

  const computeAv = (data) => {
    if (data.length === 0) {
      alert("No inSAR data exists for this area");
      return false;
    }
    const cropData = {};
    let dates = Object.keys(data[0].properties);
    dates.map((date) => {
      let sum = 0;
      data.map((f) => {
        sum += f.properties[date];
      });
      cropData[date] = Number((sum / data.length).toFixed(2));
    });
    return cropData;
  };

  const removeMapSource = () => {
    globalMap.removeLayer("search-polygon");
    globalMap.removeLayer("av-polygon-outline");
    globalMap.removeSource("search-polygon-source");
    setAvTool(!avTool);
    globalMap.getCanvas().style.cursor = "grab";
  };

  async function sendΑvRequest(polygon) {
    const response = await getAvReq(polygon);
    // console.log(response);
    setAvValues(response);
    return response;
  }

  const getDataFromPolygon = (tool, changeStyle) => {
    if (changeStyle && !tool) return;
    polygon.data.geometry.coordinates[0].length = 0;
    setDisplayStationData(false);
    // when avTool is true the user has already used the tool and want to diactivate it
    if (tool) {
      setAvTool(false);
      removeMapSource();
      setavPolygon({});
      ActiveAvTool = false;
      return;
    }
    if (!tool) {
      setAvTool(true);
      ActiveAvTool = true;
    }

    globalMap.getCanvas().style.cursor = "crosshair";
    globalMap.addSource("search-polygon-source", polygon);

    globalMap.addLayer({
      id: "search-polygon",
      type: "fill",
      source: "search-polygon-source", // reference the data source
      layout: {},
      paint: {
        "fill-color": "#fff",
        "fill-opacity": 0.1,
      },
    });
    // Outline around the polygon.
    globalMap.addLayer({
      id: "av-polygon-outline",
      type: "line",
      source: "search-polygon-source",
      layout: {},
      paint: {
        "line-color": "#fff",
        "line-width": 2,
        "line-dasharray": [2, 1],
      },
    });
    let count = 0;

    globalMap.on("click", (e) => {
      if (ActiveAvTool) {
        if (globalMap.getLayer("search-polygon-2") !== undefined) {
          globalMap.removeLayer("search-polygon-2");
          globalMap.removeLayer("outline");
          globalMap.removeSource("search-polygon-2");
        }
        polygon.data.geometry.coordinates[0].push([e.lngLat.lng, e.lngLat.lat]);
        globalMap.getSource("search-polygon-source").setData(polygon.data);
        if (polygon.data.geometry.coordinates[0].length > 2) {
          globalMap.on("contextmenu", () => {
            count++;
            if (ActiveAvTool && count % 4 === 1) {
              // console.log(loadedData.current);
              const SAR_data = loadedData.current.filter(
                (dataset) => dataset.properties.Dataset === "SAR_points"
              );
              const avData = computeAv(
                getFeatureWithin(...SAR_data, polygon.data)
              );
              if (!avData) return;
              sendΑvRequest({
                type: "FeatureCollection",
                features: [polygon.data],
              });
              setavPolygon(polygon);
              // console.log(polygon, count);
              setAvData(avData);
              setNewAvData(avData);
              setfirst_LastDate(calc_FL_dates(avData));
              sChart(true);
            }
          });
        }
      }
    });
    globalMap.on("mousemove", (e) => {
      if (ActiveAvTool) {
        let [mouseX, mouseY] = [e.point.x, e.point.y];
        setCursorBox({
          show: true,
          x: mouseX,
          y: mouseY,
          content: "Use the Left Click to draw a Polygon",
        });
        if (polygon.data.geometry.coordinates[0].length > 2) {
          setCursorBox({
            show: true,
            x: mouseX,
            y: mouseY,
            content: "Use the Right Click to Compute the Average",
          });
        }
      }
    });
  };

  function calc_FL_dates(data) {
    let dates = Object.keys(data);
    return [dates[0], dates[dates.length - 2]];
  }

  const showHideLayer = (layer, props) => {
    if (globalMap.getLayer(layer) === undefined) {
      if (layer === "meteo-stations") {
        sendMeteoRequest({ setMeteoC });
        setMeteoC(true);
        return;
      }
      if (layer === "geology-map") {
        sendGeologRequest({ setGeologMap });
        setGeologMap(true);
        return;
      }
    }

    if (globalMap.getLayer(layer)) {
      const vis = globalMap.getLayoutProperty(layer, "visibility");
      if (vis === "visible") {
        globalMap.setLayoutProperty(layer, "visibility", "none");
      } else {
        if (layer.includes("PointCloud")) {
          pointclouds.current.features.forEach((pCloud) => {
            if (pCloud.properties.site === layer.replace("PointCloud-", "")) {
              mapFlyTo(pCloud.geometry.coordinates);
            }
          });
        }
        globalMap.setLayoutProperty(layer, "visibility", "visible");
      }
    } else if (layer.includes("PointCloud")) {
      displayTLS(null, props);
      pointclouds.current.features.forEach((pCloud) => {
        if (pCloud.properties.site === layer.replace("PointCloud-", "")) {
          mapFlyTo(pCloud.geometry.coordinates);
        }
      });
    }
  };

  function createPointCloudURL(url, site) {
    setLoadingPC(site);
    const URL = `${url}`;
    setPointCloudURL(URL);
    loadedPointcloud[site] = true;
    setLoadedPointCloud(loadedPointcloud);
    setPointCloudC(true);
  }

  function diplayPoindCloudVideo(props, show) {
    sendPCVideoRequest(props.video);
    pointCloudVideoStatus[props.site] = show;
    setPointCloudVideoStatus(pointCloudVideoStatus);
  }

  async function sendPCVideoRequest(video) {
    const response = await getVideoReq(video, "point-clouds");
    setVideoURL(response);
    setVideoURLStatus(true);
  }

  function addDataset(dataset) {
    setGlobalActivatedDatasets((prevGlobalActivatedDatasets) => ({
      ...prevGlobalActivatedDatasets,
      [dataset]: !prevGlobalActivatedDatasets[dataset],
    }));
  }

  function handleChartData(dataset) {
    for (let d = 0; d < dataChartData.length; d++) {
      if (dataChartData[d].properties.Dataset === dataset.properties.Dataset) {
        dataChartData[d] = dataset;
        setShowDataChart(true);
        return;
      }
    }
    setDataChartData(dataChartData.concat([dataset]));
    setShowDataChart(true);
  }

  function checkDataQuality(dataset) {
    let notNull = false;
    if (dataset.properties.Dataset === "SAR_points") {
      if (dataset.features.length > 0) notNull = true;
    } else {
      for (let i = 0; i < dataset.properties.values.length; i++) {
        if (dataset.properties.Datset === "GNSS") {
          if (dataset.properties.values[i].valueE !== null) {
            notNull = true;
            break;
          }
        } else {
          if (dataset.properties.values[i].value !== null) {
            notNull = true;
            break;
          }
        }
      }
    }
    return notNull;
  }

  function getDatasetBeginDate(dataset) {
    let begin_Date;
    if (dataset.properties.Dataset === "SAR_points") {
      begin_Date = Object.keys(dataset.features[0].properties);
      begin_Date = `${begin_Date[0][0]}${begin_Date[0][1]}${begin_Date[0][2]}${begin_Date[0][3]}-${begin_Date[0][4]}${begin_Date[0][5]}-${begin_Date[0][6]}${begin_Date[0][7]}`;
    } else {
      let l = dataset.properties.values.length - 1;
      begin_Date = dataset.properties.values[l].date;
    }
    return begin_Date;
  }

  function getDatasetEndDate(dataset) {
    let end_Date;
    if (dataset.properties.Dataset === "SAR_points") {
      end_Date = Object.keys(dataset.features[0].properties);
      let l = end_Date.length - 2;
      end_Date = `${end_Date[l][0]}${end_Date[l][1]}${end_Date[l][2]}${end_Date[l][3]}-${end_Date[l][4]}${end_Date[l][5]}-${end_Date[l][6]}${end_Date[l][7]}`;
    } else {
      let l = dataset.properties.values.length - 1;
      end_Date = dataset.properties.values[l].date;
    }
    return end_Date;
  }

  return (
    <>
      <div className="map-toolbar">
        <div className="map-toolbar-container">
          <div>
            <button
              type="button"
              className="map-toolbar-btns"
              id="layers-btn"
              onClick={() => setShowLegendTool(!showLegendTool)}
            >
              {!showLegendTool ? (
                <FontAwesomeIcon icon={faLayerGroup} />
              ) : (
                <FontAwesomeIcon icon={faX} />
              )}
              {!showLegendTool && (
                <span className="left-tooltip">Show Legend</span>
              )}
            </button>
            {showLegendTool && (
              <div className="layers-box">
                <div className="layer-legend">
                  <input
                    className="layers-cb"
                    type="checkbox"
                    id="sites"
                    onChange={(e) => {
                      setSitesC(!sitesC);
                      showHideLayer(e.target.id);
                    }}
                    checked={sitesC}
                  ></input>
                  <img
                    src={site_markers}
                    className="layer-symbol tile"
                    name="st"
                  ></img>
                  <label className="layer-label" htmlFor="st">
                    Sites
                  </label>
                </div>
                <div className="layer-legend">
                  <input
                    className="layers-cb"
                    type="checkbox"
                    id="reflectores"
                    onChange={(e) => {
                      setReflectorsC(!reflectorsC);
                      showHideLayer(e.target.id);
                    }}
                    checked={reflectorsC}
                  ></input>
                  <img
                    src={reflectors_markers}
                    className="layer-symbol tile"
                    name="st"
                  ></img>
                  <label className="layer-label" htmlFor="st">
                    Reflectors
                  </label>
                </div>
                <div className="layer-legend">
                  <input
                    className="layers-cb"
                    type="checkbox"
                    id="meteo-stations"
                    onChange={(e) => {
                      setMeteoC(!meteoC);
                      showHideLayer(e.target.id);
                    }}
                    checked={meteoC}
                  ></input>
                  <img
                    src={meteo_markers}
                    className="layer-symbol tile"
                    name="st"
                  ></img>
                  <label className="layer-label" htmlFor="st">
                    Meteo Stations
                  </label>
                </div>
                <div className="layer-legend">
                  <input
                    className="layers-cb"
                    type="checkbox"
                    id="RSS-markers"
                    onChange={(e) => {
                      setRSSC(!RSSC);
                      showHideLayer("RSS-LH-markers");
                      showHideLayer(e.target.id);
                    }}
                    checked={RSSC}
                  ></input>
                  <img
                    src={rss_label}
                    className="layer-symbol marker"
                    name="rss"
                  ></img>
                  <label className="layer-label" htmlFor="rss">
                    48h Earthquackes Events
                  </label>
                </div>
                <div className="layer-legend">
                  <input
                    className="layers-cb"
                    type="checkbox"
                    id="geology-map"
                    onChange={(e) => {
                      setGeologMap(!geologMap);
                      showHideLayer(e.target.id);
                    }}
                    checked={geologMap}
                  ></input>
                  <img
                    src={geology_label}
                    className="layer-symbol tile"
                    name="geodep_los"
                  ></img>
                  <label className="layer-label" htmlFor="geodep_los">
                    Geological Map
                  </label>
                </div>
                {geodepC && (
                  <div className="layer-legend">
                    <img
                      src={insar_color_ramp}
                      className="layer-symbol ramp"
                      name="geodep_los"
                    ></img>
                    <label className="layer-label" htmlFor="geodep_los">
                      inSAR Disp. (mm)
                    </label>{" "}
                  </div>
                )}
                {showDataTabTool && (
                  <>
                    <div className="data-box">
                      <div className="datatool-station-header">Site: {AOI}</div>
                      {loadedData.current.map((dataset, key) => {
                        let notNall = checkDataQuality(dataset);
                        return (
                          <>
                            <div key={key}>
                              <div className="datatool-dataset-header">
                                {dataset.properties.Dataset === "SAR_points" ? (
                                  <input
                                    type="checkbox"
                                    className="datatool-dataset-checkbox"
                                    id="SAR_points"
                                    onClick={(e) => {
                                      setGeodepC(!geodepC);
                                      showHideLayer(e.target.id);
                                      console.log("SAR");
                                    }}
                                    key={key}
                                    checked={geodepC}
                                  />
                                ) : notNall ? (
                                  <input
                                    type="checkbox"
                                    className="datatool-dataset-checkbox"
                                    id={dataset.properties.Dataset}
                                    onClick={(e) => {
                                      addDataset(dataset.properties.Dataset);
                                    }}
                                    key={key}
                                    checked={
                                      globalActivatedDatasets[
                                        dataset.properties.Dataset
                                      ]
                                    }
                                  />
                                ) : null}

                                {dataset.properties.Dataset.replace("_", " ")}
                                {dataset.properties.Dataset.split("_")
                                  .length === 1 && notNall ? (
                                  <button
                                    className="datatool-chart-btn"
                                    onClick={() => handleChartData(dataset)}
                                  >
                                    <FontAwesomeIcon icon={faChartLine} />
                                  </button>
                                ) : null}
                              </div>
                              <div className="datatool-dataset-context">
                                {notNall ? (
                                  <>
                                    <span className="datatool-dataset-date">
                                      Begin Date: {getDatasetBeginDate(dataset)}
                                    </span>
                                    <br></br>
                                    <span className="datatool-dataset-date">
                                      End Date: {getDatasetEndDate(dataset)}
                                    </span>{" "}
                                  </>
                                ) : (
                                  "No observation satisfies those filters"
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <button
            type="button"
            className="map-toolbar-btns"
            id="point-clouds-btn"
          >
            <FontAwesomeIcon icon={faCubes} />
            <div className="point-cloud-box">
              <div className="legend-title">3D Point Cloud</div>
              {pointclouds.current !== null &&
                pointclouds.current.features.map((pcloud) => {
                  return (
                    <>
                      {pcloud.properties.site === AOI && (
                        <label
                          htmlFor={`PointCloud-${pcloud.properties.site}`}
                          className="point-cloud-labels"
                          id={`point-cloud-img-${pcloud.properties.site}`}
                          onClick={(e) => {
                            loadedPointcloud[pcloud.properties.site]
                              ? showHideLayer(
                                  `PointCloud-${pcloud.properties.site}`,
                                  pcloud.properties
                                )
                              : createPointCloudURL(
                                  pcloud.properties.file,
                                  pcloud.properties.site
                                );
                          }}
                          onMouseEnter={() =>
                            diplayPoindCloudVideo(pcloud.properties, true)
                          }
                          onMouseLeave={() =>
                            diplayPoindCloudVideo(pcloud.properties, false)
                          }
                        >
                          {pcloud.properties.name}
                          <br></br>({pcloud.properties.resolution} -{" "}
                          {pcloud.properties.size})
                          <div className="point-cloud-loading-image">
                            {loadingReqPercentage > 0 &&
                              loadingReqPercentage < 100 &&
                              loadingPC === pcloud.properties.site && (
                                <span className="point-cloud-loading-percentage">
                                  {loadingReqPercentage.toFixed(0)}%
                                </span>
                              )}
                            {/* <span className="point-cloud-loading-percentage">{loadingReqPercentage}%</span> */}
                            <img
                              className="basemap-img"
                              src={
                                pcloud.properties.site === "Geodep"
                                  ? Geodep_PC_thumbnail
                                  : pcloud.properties.site === "Krini"
                                  ? Krini_PC_thumbnail
                                  : pcloud.properties.site === "Asteri"
                                  ? Asteri_PC_thumbnail
                                  : null
                              }
                              name={`PointCloud-${pcloud.properties.site}`}
                              id="point-cloud-label"
                            />
                          </div>
                        </label>
                      )}
                      <>
                        {pointCloudVideoStatus[pcloud.properties.site] && (
                          <div className="point-cloud-video-box">
                            {videoURLStatus && showVideo ? (
                              <video width="100%" autoPlay muted controls>
                                <source src={videoURL} type="video/mp4" />
                              </video>
                            ) : !videoURLStatus ? (
                              <MediaLoader />
                            ) : null}
                          </div>
                        )}
                      </>
                    </>
                  );
                })}
              <div className="point-clouds-disclaimer">
                * The loading time of the point cloud depends on the indicated
                size of each file. Due to it, the application possibly does not
                respond for a while, until the point cloud is handled
                successfully.
                <br />* The point cloud displayed on the map are not in its
                original resolution but has been simplified for achieving
                optimal displaying performance.{" "}
              </div>
            </div>
          </button>
          {geodepC && (
            <button
              type="button"
              className="map-toolbar-btns"
              id="comp-average-btn"
              onClick={() => {
                // setActiveAvTool(!ActiveAvTool).then;
                getDataFromPolygon(avTool, false);
              }}
            >
              {!avTool ? (
                <FontAwesomeIcon icon={faDrawPolygon} />
              ) : (
                <FontAwesomeIcon icon={faX} />
              )}
              <span className="left-tooltip">
                {avTool ? "Revome Polygon" : "Compute Average"}
              </span>
              {/* {avTool ? (
                <span className='rightClick-tooltip'>
                  Draw a Polygon and press Right Click to Compute the Average
                </span>
              ) : null} */}
            </button>
          )}

          <button
            type="button"
            className="map-toolbar-btns"
            id="taggle-3d-btn"
            onClick={() => taggle2D3D()}
          >
            <FontAwesomeIcon icon={faCube} />
            <span className="left-tooltip">Toggle {Dtooltip}</span>
          </button>
          <button type="button" className="map-toolbar-btns" id="basemaps-btn">
            <FontAwesomeIcon icon={faMap} />
            <div className="basemaps-box">
              <label
                htmlFor="streetMap"
                className="basemaps-labels"
                onClick={(e) => {
                  chMapStyle(e.target.id);
                  getDataFromPolygon(avTool, true);
                }}
              >
                Οutdoors-v12
                <img
                  className="basemap-img"
                  src={streetMap}
                  name="streetMap"
                  id="outdoors-v12"
                ></img>
              </label>

              <label
                htmlFor="sateMap"
                className="basemaps-labels"
                onClick={(e) => {
                  chMapStyle(e.target.id);
                  getDataFromPolygon(avTool, true);
                }}
              >
                Satellite-streets-v12
                <img
                  className="basemap-img"
                  src={sateMap}
                  name="sateMap"
                  id="satellite-streets-v12"
                ></img>
              </label>

              <label
                htmlFor="darkMap"
                className="basemaps-labels"
                onClick={(e) => {
                  chMapStyle(e.target.id);
                  getDataFromPolygon(avTool, true);
                }}
              >
                Dark-v11
                <img
                  className="basemap-img"
                  src={darkMap}
                  name="darkMap"
                  id="dark-v11"
                ></img>
              </label>

              <label
                htmlFor="lightMap"
                className="basemaps-labels"
                onClick={(e) => {
                  chMapStyle(e.target.id);
                  getDataFromPolygon(avTool, true);
                }}
              >
                Light-v11
                <img
                  className="basemap-img"
                  src={lightMap}
                  name="lightMap"
                  id="light-v11"
                ></img>
              </label>
            </div>
          </button>
          {showChartBtn && (
            <button
              type="button"
              className="map-toolbar-btns"
              id="chart-btn"
              onClick={() => {
                setShowChart(true);
                setShowDataChart(false);
              }}
            >
              <FontAwesomeIcon icon={faChartLine} />
              <span className="left-tooltip">Show Chart</span>
            </button>
          )}
        </div>
      </div>
      {![false, null, undefined].includes(avValues) && avTool && (
        <div className="Distance-small-tab">
          <div className="form-headers" id="form-distance-header">
            Compute Average
          </div>
          <div className="form-groups" id="form-distance-group">
            <table>
              <tbody>
                <tr>
                  <td>Mean Elevation: </td>
                  <td>{avValues.mean_elevation}</td>
                </tr>
                <tr>
                  <td>Mean Slope:</td>
                  <td>{avValues.mean_slope}</td>
                </tr>
                <tr>
                  <td>Area:</td>
                  <td>{avValues.area}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {avTool && (
        <div
          className="cursor-box"
          style={{ top: cursorBox.y, left: cursorBox.x }}
        >
          {cursorBox.content}
        </div>
      )}
      {showDataChart && (
        <DataPlotChart
          Data={dataChartData}
          setShowDataChart={setShowDataChart}
          site={AOI}
        />
      )}
    </>
  );
};

export { MapTools };
