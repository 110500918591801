import ReactDOM from "react-dom/client";
import { useRef, useEffect, useState } from "react";

//MapBox
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "mapbox-gl-geocoder";
import "mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

// DeckGL
import { Deck } from "@deck.gl/core";
import { MapboxLayer } from "@deck.gl/mapbox";
import { PointCloudLayer } from "@deck.gl/layers";

// Extra Lib
import { lengthToDegrees } from "@turf/helpers";
import "chart.js/auto";

//Components
import { DummyPlotChart, PlotChart } from "./Chart.js";
import { MapTools } from "./MapTools";
// import Loading from "./Loading";

//Popups
import {
  GNSSPopup,
  RSSPopup,
  ReflectorsPopup,
  MeteoPopup,
  GeologMapTooltip,
} from "./Popups.js";

//Images
import proionFundingImg from "./img/funding.png";
import reflectors_markers from "./img/markers/reflectore_marker.png";
import site_markers from "./img/markers/site_marker.png";
import meteo_markers from "./img/markers/meteo_marker.png";

//External Functions
import { getLocalDataReq } from "./Requests.js";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

let globalMap;
const stationsOnMap = [];
const globalDataList = [];

const globalTLSData = [];

const pointcloudsCapabilities = [];
// ------------------ Data Requests ---------------------------
async function sendRSSRequest() {
  const response = await getLocalDataReq("earthquakes");
  addRSS(response);
}

async function sendReflectorsRequest() {
  const response = await getLocalDataReq("reflectors");
  addReflectorsOnMap(response);
}

async function sendMeteoRequest({ setMeteoC }) {
  const response = await getLocalDataReq("meteo");
  addMeteoOnMap({ response, setMeteoC });
}

async function sendGeologRequest({ setGeologMap }) {
  const response = await getLocalDataReq("geologic");
  addGeologMap({ response, setGeologMap });
}
// ----------------------------

const createColorPallet = () => {
  return [
    "step",
    ["number", ["get", "mean_disp"]],
    "#f00",
    -20,
    "#ffac00",
    -13.4,
    "#ccff00",
    -6.6,
    "#00ff02",
    0,
    "#00ffcc",
    6.6,
    "#00a7ff",
    13.4,
    "#0000ff",
    20,
    "#02026a",
  ];
};

const highlightPoint = (coords) => {
  const creatPointGeojson = () => {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords,
          },
        },
      ],
    };
  };

  globalMap.flyTo({
    center: coords,
    zoom: 14,
  });

  for (const layer of globalMap.getStyle().layers) {
    if (layer.id === "highlight") {
      globalMap.getSource("point-circle").setData(creatPointGeojson());
      return;
    }
  }
  globalMap.addSource("point-circle", {
    type: "geojson",
    data: creatPointGeojson(),
  });

  globalMap.addLayer(
    {
      id: "highlight",
      type: "circle",
      source: "point-circle",
      paint: {
        "circle-color": "#fff",
        "circle-radius": 20,
        "circle-opacity": 0,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    }
    // layer_id
  );
};

const displayPolygon = (polygon) => {
  globalMap.addSource("search-polygon-2", polygon);

  globalMap.addLayer({
    id: "search-polygon-2",
    type: "fill",
    source: "search-polygon-2", // reference the data source
    layout: {},
    paint: {
      "fill-color": "#fff",
      "fill-opacity": 0.1,
    },
  });
  // Outline around the polygon.
  globalMap.addLayer({
    id: "outline",
    type: "line",
    source: "search-polygon-2",
    layout: {},
    paint: {
      "line-color": "#fff",
      "line-width": 1.2,
    },
  });
};

const getDates = (prop) => {
  let keys = Object.getOwnPropertyNames(prop);
  return [keys[0], keys[keys.length - 6]];
};

const displayArrows = (data, geodepC) => {
  let arrowsGeojson = {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  };

  let maxLenght = 50;
  let maxNorth = 0;
  let maxEast = 0;

  let dep = [];
  data.features.map((f) => {
    let North = Math.floor(Math.random() * (10 - -10 + 1) + -10);
    let East = Math.floor(Math.random() * (10 - -10 + 1) + -10);
    dep.push([North, East]);
    if (Math.abs(North) > maxNorth) maxNorth = North;
    if (Math.abs(East) > maxEast) maxEast = East;
  });

  data.features.map((f, index) => {
    let newNorth =
      f.geometry.coordinates[1] +
      lengthToDegrees((dep[index][0] / maxNorth) * maxLenght, "meters");
    let newEast =
      f.geometry.coordinates[0] +
      lengthToDegrees((dep[index][1] / maxEast) * maxLenght, "meters");
    let rres =
      Math.sqrt(
        Math.pow(dep[index][0], 2) + Math.pow(dep[index][1], 2)
      ).toFixed(2) * 1;
    let fres =
      Math.sqrt(Math.pow(newNorth, 2) + Math.pow(newEast, 2)).toFixed(2) * 1;
    arrowsGeojson.data.features.push({
      type: "Feature",
      properties: {
        North: dep[index][0],
        East: dep[index][1],
        RRES: rres,
        FRES: fres,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [f.geometry.coordinates[0], f.geometry.coordinates[1]],
          [newEast, newNorth],
        ],
      },
    });
  });

  // console.log(arrowsGeojson.data);

  globalMap.addSource("geodep_arrows", arrowsGeojson);
  globalMap.addLayer({
    id: "geodep_arrows",
    type: "line",
    source: "geodep_arrows",
    layout: {
      // Make the layer visible by default.
      visibility: geodepC ? "visible" : "none",
    },
    paint: {
      "line-width": {
        base: 2.5,
        stops: [
          [8, 0.5],
          [12, 2],
          [22, 4],
        ],
      },
      "line-color": [
        "step",
        ["number", ["get", "RRES"]],
        "#f00",
        -20,
        "#ffac00",
        -13.4,
        "#ccff00",
        -6.6,
        "#00ff02",
        0,
        "#00ffcc",
        6.6,
        "#00a7ff",
        13.4,
        "#0000ff",
        20,
        "#fff",
      ],
    },
  });
};

function getSARBounds(geojson) {
  const bounds = new mapboxgl.LngLatBounds();
  geojson.features.forEach((feature) => {
    const coords = feature.geometry.coordinates;
    bounds.extend(coords);
  });
  return bounds;
}

const addGridDataToMap = ({
  layer_id,
  setPointData,
  setfirst_LastDate,
  setShowChart,
  setnewPointData,
  setNewFirstDate,
  setNewLastDate,
  setDisplayStationData,
  setHilighPointCoords,
  geodepC,
  setShowChartBtn,
}) => {
  // console.log(globalDataList);
  let gridDataset = null;
  for (let dataset of globalDataList) {
    if (dataset.properties.Dataset === "SAR_points") {
      gridDataset = dataset;
    }
  }

  if (gridDataset === null) return;

  for (let f of gridDataset.features) {
    let f_values = Object.values(f.properties);
    let values_sum = 0;
    f_values.forEach((value) => {
      values_sum += value;
    });
    const mean_values = values_sum / f_values.length;
    f.properties.mean_disp = mean_values;
  }
  if (globalMap.getSource("geodep") !== undefined) {
    globalMap.getSource("geodep").setData(gridDataset);

    globalMap.fitBounds(getSARBounds(gridDataset), { padding: 200 });
    setShowChart(false);
    return;
  }

  globalMap.addSource("geodep", {
    type: "geojson",
    data: gridDataset,
  });

  globalMap.addLayer(
    {
      id: layer_id,
      type: "circle",
      source: "geodep",
      layout: {
        // Make the layer visible by default.
        visibility: geodepC ? "visible" : "none",
      },
      paint: {
        "circle-radius": {
          base: 1.5,
          stops: [
            [8, 0.4],
            [12, 2.5],
            [15, 6],
          ],
        },
        "circle-stroke-width": 0,
        "circle-color": createColorPallet(gridDataset),
      },
    },
    "sites",
    "meteo-points",
    "reflectores"
  );

  globalMap.fitBounds(getSARBounds(gridDataset), { padding: 200 });

  globalMap.on("click", layer_id, (e) => {
    let dates = getDates(e.features[0].properties);
    setDisplayStationData(false);
    setNewFirstDate(dates[0]);
    setNewLastDate(dates[1]);
    setfirst_LastDate(dates);
    setPointData(e.features[0].properties);
    setnewPointData(e.features[0].properties);
    highlightPoint(e.features[0].geometry.coordinates);
    setHilighPointCoords(e.features[0].geometry.coordinates);
    setShowChart(true);
    setShowChartBtn(true);
  });

  // Change the cursor to a pointer when the it enters a feature in the 'data-layer' layer.
  globalMap.on("mouseenter", layer_id, () => {
    globalMap.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  globalMap.on("mouseleave", layer_id, () => {
    globalMap.getCanvas().style.cursor = "";
  });
};

const addStationsToMap = (getCapabilities) => {
  // console.log(getCapabilities);
  const popupNode = (site_name, capabilities) => {
    const popupNode = document.createElement("div");
    const root = ReactDOM.createRoot(popupNode);
    root.render(
      <GNSSPopup site_name={site_name} capabilities={capabilities} />
    );
    return popupNode;
  };

  globalMap.loadImage(site_markers, (error, image) => {
    if (error) throw error;
    // Add the image to the map style.
    globalMap.addImage(`site-img`, image);

    // Add a data source containing one point feature.
    globalMap.addSource(`site-points`, {
      type: "geojson",
      data: getCapabilities,
    });

    // Add a layer to use the image to represent the data.
    globalMap.addLayer({
      id: "sites",
      type: "symbol",
      source: `site-points`, // reference the data source
      layout: {
        "icon-image": `site-img`, // reference the image
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0.1,
          10,
          0.14,
          20,
          0.21, // at zoom level 20, icon-size will be 1.0
        ],
        "icon-anchor": "bottom",
        visibility: "visible",
      },
    });
    globalMap.on("click", (e) => {
      let features = globalMap.queryRenderedFeatures(e.point, {
        layers: [`sites`],
      });
      globalMap.getCanvas().style.cursor = features.length ? "pointer" : "";
      if (!features.length) {
        return;
      }
      // console.log(getCapabilities);
      let feature = features[0];
      const popup = new mapboxgl.Popup({})
        .setLngLat(feature.geometry.coordinates)
        .setOffset([0, -40])
        .setDOMContent(popupNode(feature.properties.station, getCapabilities))
        .addTo(globalMap);
    });
    globalMap.on("mouseenter", `sites`, () => {
      globalMap.getCanvas().style.cursor = "pointer";
    });
    globalMap.on("mouseleave", `sites`, () => {
      globalMap.getCanvas().style.cursor = "";
      // popup.remove();
    });
  });
};

const setStationsData = ({
  properties,
  setDisplayStationData,
  setfirst_LastDate,
  setnewPointData,
  orbitOption,
  setShowChart,
  setStationProperties,
  setPointData,
}) => {
  // console.log(properties);
  let values = properties.values;
  setDisplayStationData(true);
  setStationProperties(properties);
  const orbitData = {};
  values.map((v) => {
    let date = orbitOption + " date";
    orbitData[v[date]] = v[orbitOption];
  });
  setfirst_LastDate([
    properties[orbitOption + " Begin Date"],
    properties[orbitOption + " End Date"],
  ]);
  setnewPointData(orbitData);
  setPointData(orbitData);
  setShowChart(true);
};

function addRSS(RSSData) {
  const RSS_geoJson = {
    type: "FeatureCollection",
    features: [],
  };
  const RSS_LH_geoJson = {
    type: "FeatureCollection",
    features: [],
  };
  const currentTime = new Date();
  RSSData.rss.channel.item.forEach((e) => {
    const f = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Point",
        coordinates: [Number(e["geo:long"]), Number(e["geo:lat"])], // icon position [lng, lat]
      },
    };
    Object.getOwnPropertyNames(e).forEach((p) => {
      f.properties[p] = e[p];
    });

    let event_time = f.properties["pubDate"].split(" ");
    event_time[0] = event_time[0].split("/");
    event_time = `${event_time[0][2]}-${event_time[0][1]}-${event_time[0][0]}T${event_time[1]}Z`;
    event_time = new Date(event_time);
    let dif = (currentTime - event_time) / (1000 * 60 * 60);
    if (dif <= 1) {
      RSS_LH_geoJson.features.push(f);
    } else {
      RSS_geoJson.features.push(f);
    }
  });

  globalMap.addSource("RSS-LH-markers", {
    type: "geojson",
    data: RSS_geoJson,
  });

  globalMap.addLayer({
    id: "RSS-LH-markers",
    type: "circle",
    source: "RSS-LH-markers",
    layout: {
      // Make the layer visible by default.
      visibility: "visible",
    },
    paint: {
      "circle-radius": {
        base: 10,
        stops: [
          [8, 6],
          [12, 8],
          [15, 10],
        ],
      },
      "circle-stroke-width": 2,
      "circle-stroke-color": "#fff",
      "circle-color": "#FF6464",
    },
  });

  let size = 100;
  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d", { willReadFrequently: true });
    },

    // Call once before every frame where the icon will be used.
    render: function () {
      const duration = 2000;
      const t = (performance.now() % duration) / duration;

      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;

      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = "rgba(255, 100, 100, 1)";
      context.strokeStyle = "white";
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      globalMap.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    },
  };

  globalMap.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

  globalMap.addSource("dot-point", {
    type: "geojson",
    data: RSS_LH_geoJson,
  });
  globalMap.addLayer({
    id: "RSS-markers",
    type: "symbol",
    source: "dot-point",
    layout: {
      "icon-image": "pulsing-dot",
      visibility: "visible",
    },
  });
  const popup = new mapboxgl.Popup({});
  addRSSLayer("RSS-LH-markers");
  addRSSLayer("RSS-markers");
  function addRSSLayer(layerID) {
    globalMap.on("click", layerID, (e) => {
      // Change the cursor style as a UI indicator.
      globalMap.getCanvas().style.cursor = "pointer";

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the globalMap is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      //Init RSS tooltip HTML dom
      const popupNode = document.createElement("div");
      const root = ReactDOM.createRoot(popupNode);
      root.render(<RSSPopup rss={e.features[0].properties} />);

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setDOMContent(popupNode).addTo(globalMap);
    });
    globalMap.on("mouseenter", layerID, () => {
      globalMap.getCanvas().style.cursor = "pointer";
    });
    globalMap.on("mouseleave", layerID, () => {
      globalMap.getCanvas().style.cursor = "";
      // popup.remove();
    });
  }
}

const addReflectorsOnMap = (data) => {
  const popupNode = (props) => {
    const popupNode = document.createElement("div");
    const root = ReactDOM.createRoot(popupNode);
    root.render(<ReflectorsPopup props={props} />);
    return popupNode;
  };

  globalMap.loadImage(reflectors_markers, (error, image) => {
    if (error) throw error;

    // Add the image to the map style.
    globalMap.addImage("reflector", image);

    // Add a data source containing one point feature.
    globalMap.addSource("reflectore-points", {
      type: "geojson",
      data: data,
    });

    // Add a layer to use the image to represent the data.
    globalMap.addLayer({
      id: "reflectores",
      type: "symbol",
      source: "reflectore-points", // reference the data source
      layout: {
        "icon-image": "reflector", // reference the image
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0.1,
          10,
          0.14,
          20,
          0.21,
        ],
        "icon-anchor": "bottom",
        visibility: "visible",
      },
    });
    globalMap.on("click", (e) => {
      let features = globalMap.queryRenderedFeatures(e.point, {
        layers: ["reflectores"],
      });
      globalMap.getCanvas().style.cursor = features.length ? "pointer" : "";
      if (!features.length) {
        return;
      }
      // console.log(features);
      let feature = features[0];
      const popup = new mapboxgl.Popup({})
        .setLngLat(feature.geometry.coordinates)
        .setOffset([0, -40])
        .setDOMContent(popupNode(feature))
        .addTo(globalMap);
    });
    globalMap.on("mouseenter", "reflectores", () => {
      globalMap.getCanvas().style.cursor = "pointer";
    });
    globalMap.on("mouseleave", "reflectores", () => {
      globalMap.getCanvas().style.cursor = "";
      // popup.remove();
    });
  });
};

const addMeteoOnMap = (meteoprops) => {
  const popupNode = (props) => {
    const popupNode = document.createElement("div");
    const root = ReactDOM.createRoot(popupNode);
    root.render(<MeteoPopup props={props} />);
    return popupNode;
  };

  globalMap.loadImage(meteo_markers, (error, image) => {
    if (error) throw error;

    // Add the image to the map style.
    globalMap.addImage("meteo-img", image);

    // Add a data source containing one point feature.
    globalMap.addSource("meteo-points", {
      type: "geojson",
      data: meteoprops.response,
    });

    // Add a layer to use the image to represent the data.
    globalMap.addLayer({
      id: "meteo-stations",
      type: "symbol",
      source: "meteo-points", // reference the data source
      layout: {
        visibility: "visible",
        "icon-image": "meteo-img", // reference the image
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0,
          0.1,
          10,
          0.14,
          20,
          0.21,
        ],
        "icon-anchor": "bottom",
      },
    });
    globalMap.on("click", (e) => {
      let features = globalMap.queryRenderedFeatures(e.point, {
        layers: ["meteo-stations"],
      });
      globalMap.getCanvas().style.cursor = features.length ? "pointer" : "";
      if (!features.length) {
        return;
      }
      // console.log(features);
      let feature = features[0];
      const popup = new mapboxgl.Popup({})
        .setLngLat(feature.geometry.coordinates)
        .setOffset([0, -40])
        .setDOMContent(popupNode(feature))
        .addTo(globalMap);
    });
    globalMap.on("mouseenter", "meteo-stations", () => {
      globalMap.getCanvas().style.cursor = "pointer";
    });
    globalMap.on("mouseleave", "meteo-stations", () => {
      globalMap.getCanvas().style.cursor = "";
      // popup.remove();
    });
  });
};

function addGeologMap(props) {
  // console.log(props);
  const polygon = {
    type: "geojson",
    data: props.response,
  };

  globalMap.addSource("geology-map", polygon);
  globalMap.addLayer({
    id: "geology-map",
    type: "fill",
    source: "geology-map", // reference the data source
    layout: {
      visibility: "visible",
    },
    paint: {
      "fill-color": [
        "match",
        ["get", "Geology"],
        "Ft",
        "#ffd37f",
        "Fi/1",
        "#eed9b0",
        "fo",
        "#e3fdfd",
        "al",
        "#f9fdd8",
        "jc/4",
        "#dafcb6",
        "cq1",
        "#f8f8b3",
        "mq1",
        "#f8f8b3",
        "pq/1",
        "#f8f388",
        "T",
        "#b4d79e",
        "c2/2",
        "#d7d79e",
        "cs2",
        "#dafcb6",
        "ph",
        "#f49746",
        "jc/2",
        "#cbcb67",
        "e/1",
        "#ffebaf",
        "p/2",
        "#f8f073",
        /* other */ "#fff",
      ],
      "fill-opacity": 0.8,
    },
  });

  const popup = new mapboxgl.Popup();
  globalMap.on("mouseover", "geology-map", (e) => {
    globalMap.getCanvas().style.cursor = "pointer";
  });
  globalMap.on("click", "geology-map", (e) => {
    const popupNode = document.createElement("div");
    const root = ReactDOM.createRoot(popupNode);
    root.render(<GeologMapTooltip properties={e.features[0].properties} />);

    popup.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(globalMap);
  });

  globalMap.on("mouseleave", "geology-map", () => {
    globalMap.getCanvas().style.cursor = "";
    popup.remove();
  });
}

function addHomeButton(lng, lat, zoom, mapHome, setMapHome) {
  const homePosition = {
    center: [lng, lat],
    zoom: zoom,
  };

  class HomeButton {
    onAdd(globalMap) {
      const div = document.createElement("div");
      div.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      div.innerHTML = `<button class="mapboxgl-ctrl-zoomToHome" id="home-btn">
        <span class="mapboxgl-ctrl-icon" aria-hidden="true" title="Home View">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-zoom-pan" width="29" height="29" viewBox="-1.5 -1.5 27 27" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
          <path d="M17 17l-2.5 -2.5"></path>
          <path d="M10 5l2 -2l2 2"></path>
          <path d="M19 10l2 2l-2 2"></path>
          <path d="M5 10l-2 2l2 2"></path>
          <path d="M10 19l2 2l2 -2"></path>
        </svg>
        </span>
        </button>`;
      div.addEventListener("contextmenu", (e) => e.preventDefault());
      div.addEventListener("click", () => globalMap.flyTo(homePosition));

      return div;
    }
  }
  const homeButton = new HomeButton();
  if (mapHome) {
    globalMap.removeControl(homeButton);
  }

  globalMap.addControl(homeButton, "top-right");
  setMapHome(true);
}

const Funding = () => {
  return (
    <>
      <div className="funding-window">
        <img src={proionFundingImg} className="funding-img" />
      </div>
    </>
  );
};

function displayTLS(TLS_data, props) {
  const pointCloudId = `PointCloud-${props.site}`;
  let TLSexist = false;
  for (let tls of globalTLSData) {
    if (tls.site === props.site) {
      TLS_data = tls.data;
      TLSexist = true;
    }
  }
  if (!TLSexist) {
    globalTLSData.push({
      site: props.site,
      data: TLS_data,
    });
  }
  console.log("I handle point cloud");
  const deck = new Deck({
    gl: globalMap.painter.context.gl,
    layers: [
      new PointCloudLayer({
        id: pointCloudId,
        data: TLS_data,
        sizeUnits: "meters",
        getPosition: (d) => {
          d = d.split(",");
          return [d[0] * 1, d[1] * 1, d[2] * 1 + props.transpose];
        },
        pointSize: 0.08,
        getColor: (d) => {
          d = d.replace("/r", "");
          d = d.split(",");
          return [d[3] * 1, d[4] * 1, d[5] * 1];
        },
        pickable: false,
      }),
    ],
  });
  globalMap.addLayer(new MapboxLayer({ id: pointCloudId, deck }));
  globalMap.setLayoutProperty(pointCloudId, "visibility", "visible");
}

function handleData(data) {
  // In case that the user selected a different Site with the previus one, then reinitialize-empty the globalDataList
  // console.log("-------------", globalDataList);
  console.log(globalDataList, data);
  if ([false, null, undefined].includes(data)) {
    alert(
      "Your search does not return any existing datasets.\nPlease select either different parameters or area."
    );
    return;
  }
  if (
    globalDataList.length > 0 &&
    globalDataList[0].properties.Station !== data.properties.Station
  ) {
    globalDataList.length = 0;
  }
  let exists = false;
  // loop within globalDataList to adentify if the new dataset has been requested earlier
  for (let d = 0; d < globalDataList.length; d++) {
    // If yes replaced it with the new one and switch the exist varible to "true"
    if (globalDataList[d].properties.Dataset === data.properties.Dataset) {
      globalDataList[d] = data;
      exists = true;
      break;
    }
  }
  // If exists variable remaines false add the new dataset to the globalDataList
  if (!exists) {
    globalDataList.push(data);
  }
}

const Map = ({
  setLoadingBarPercentage,
  setLoading,
  dataHandled,
  getCapabilities,
  setLoadingReqPercentage,
  loadingReqPercentage,
  AOI,
  loggedIn,
}) => {
  let [x, y, InitZoom] = [21.83830206865946, 38.175896806122665, 10];
  const initMapStyle = "satellite-streets-v12";

  const mapContainer = useRef(null);
  const map = useRef(null);
  // const [loading, setLoading] = useState(true);
  // const [loadingBarPercentage, setLoadingBarPercentage] = useState(0);
  // -- Map vars
  const [lng, setLng] = useState(x);
  const [lat, setLat] = useState(y);
  const [zoom, setZoom] = useState(InitZoom);
  const [pitch, setPitch] = useState(0);
  const [bearing, setBearing] = useState(0);
  const [mapStyle, setMapStyle] = useState(initMapStyle);
  const [highlightPointCoords, setHilighPointCoords] = useState(null);
  const [avPolygon, setavPolygon] = useState(null);
  const [mapHome, setMapHome] = useState(true);
  // -- Data vars
  // const [showForm, setShowForm] = useState(true);
  const [layer_id, setLayer_id] = useState("SAR_points");
  const [pointData, setPointData] = useState(null);
  const [newpointData, setnewPointData] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [showChartBtn, setShowChartBtn] = useState(false);
  const [first_lastDate, setfirst_LastDate] = useState([]);
  // const [mapNavs, setMapNavs] = useState(false);
  const [displayStationData, setDisplayStationData] = useState(false);
  const [newFirstDate, setNewFirstDate] = useState(null);
  const [newLastDate, setNewLastDate] = useState(null);
  const [orbitOption, setOrbitOption] = useState("Ascending");
  const [stationProperties, setStationProperties] = useState([]);
  const [geodepC, setGeodepC] = useState(false);
  const [RSSC, setRSSC] = useState(true);
  const [showDataTabTool, setShowDataTabTool] = useState(false);
  const [sitesC, setSitesC] = useState(true);
  const [reflectorsC, setReflectorsC] = useState(true);
  const [meteoC, setMeteoC] = useState(false);
  const [geologMap, setGeologMap] = useState(false);
  const [pointCloudC, setPointCloudC] = useState(false);
  const [showDataChart, setShowDataChart] = useState(false);

  // const [gridDataset, setGridDataset] = useState(null);
  const loadedData = useRef(null);

  // Declare this variable in order to detect when the globalDataList change content.
  // "true" and "false" do not means different action are only for the variable changing.
  // const [globalDataListUpdate, setGlobalDataListUpdate] = useState(false);
  // const [dataList, setDataList] = useState(globalDataList);

  // Initialisation's useEffect of the map
  // Called also When a different map style selected --> Update Map Style
  useEffect(() => {
    !loggedIn && setLoadingBarPercentage(20);
    if (map.current && mapStyle === initMapStyle) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/${mapStyle}`,
      center: [lng, lat],
      projection: "globe",
      zoom: zoom,
      pitch: pitch,
      bearing: bearing,
    });

    if (!loggedIn) return;

    globalMap = map.current;
    map.current.on("load", () => {
      // add the DEM source as a terrain layer with exaggerated height
      addMapFeatures();
      setLoadingBarPercentage(60);

      addMapLayers();

      if (sitesC) {
        addStationsToMap(getCapabilities);
      }

      setTimeout(() => {
        addGridDataToMap({
          layer_id,
          setPointData,
          setfirst_LastDate,
          setShowChart,
          setnewPointData,
          setNewFirstDate,
          setNewLastDate,
          setDisplayStationData,
          setHilighPointCoords,
          geodepC,
          setLoading,
          setLoadingBarPercentage,
          setShowChartBtn,
        });
      }, "1000");

      if (pointCloudC) {
        for (let site of pointcloudsCapabilities[0].features) {
          if (site.properties.site === AOI) {
            setTimeout(() => {
              displayTLS(null, site.properties);
            }, 2000);
            break;
          }
        }
      }
    });

    globalMap.on("dataloading", () => {
      setLoadingBarPercentage(100);
      setTimeout(() => {
        setLoading(false);
      }, 800);
    });
  }, [mapStyle]);

  //When the Chart Date change --> update the data of Chart
  useEffect(() => {
    let newdata = {};
    // console.log(pointData);
    if (pointData !== null) {
      Object.keys(pointData).map((key) => {
        if (key > newFirstDate && key < newLastDate) {
          newdata[key] = pointData[key];
        }
      });
    }

    if (Object.keys(newdata).length !== 0) {
      setnewPointData(newdata);
    }
  }, [newFirstDate, newLastDate]);

  //When the Insar orbit option change --> Update the main Chart
  useEffect(() => {
    if (!displayStationData) return;
    let properties = stationProperties;
    setStationsData({
      properties,
      setDisplayStationData,
      setfirst_LastDate,
      setnewPointData,
      orbitOption,
      setShowChart,
      setStationProperties,
      setPointData,
    });
  }, [orbitOption]);

  //When the requested data loaded --> Update the Data tab in MapTools > Legend
  useEffect(() => {
    loadedData.current = globalDataList;
    if (globalDataList.length > 0) {
      setShowDataTabTool(true);
    } else {
      setShowDataTabTool(false);
    }
    // console.log(loadedData.current);
  }, [dataHandled]);

  //When a different AOI selected --> Update Map
  useEffect(() => {
    if (!loggedIn) return;
    setLoadingBarPercentage(20);
    // if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/${mapStyle}`,
      center: [lng, lat],
      projection: "globe",
      zoom: zoom,
      pitch: pitch,
      bearing: bearing,
    });

    globalMap = map.current;

    map.current.on("load", () => {
      // add the DEM source as a terrain layer with exaggerated height
      addMapFeatures();
      if (AOI) {
        getCapabilities.features.forEach((f) => {
          if (AOI === f.properties.station) {
            globalMap.flyTo({
              center: [f.geometry.coordinates[0], f.geometry.coordinates[1]],
              zoom: 12.5,
              essentila: true,
            });
          }
        });
      }
      setLoadingBarPercentage(60);
      addMapLayers();
    });
    globalMap.on("dataloading", () => {
      setLoadingBarPercentage(100);
      setTimeout(() => {
        setLoading(false);
      }, 800);
    });
  }, [AOI]);

  // When user selects Insar points data --> Display data on Map
  useEffect(() => {
    if (geodepC) {
      addGridDataToMap({
        layer_id,
        setPointData,
        setfirst_LastDate,
        setShowChart,
        setnewPointData,
        setNewFirstDate,
        setNewLastDate,
        setDisplayStationData,
        setHilighPointCoords,
        geodepC,
        setLoading,
        setLoadingBarPercentage,
        setShowChartBtn,
      });
    }
  }, [geodepC, dataHandled]);

  // When user activates the inSAR chart --> Deactivate the dataset chart
  useEffect(() => {
    if (showChart) {
      setShowDataChart(!showChart);
    }
  }, [showChart]);

  // When user activates the dataset chart --> Deactivate the inSAR chart
  useEffect(() => {
    if (showDataChart) {
      setShowChart(!showDataChart);
    }
  }, [showDataChart]);

  // Adds Terrain and map controls
  function addMapFeatures() {
    globalMap.addSource("mapbox-dem", {
      type: "raster-dem",
      url: "mapbox://mapbox.mapbox-terrain-dem-v1",
      tileSize: 512,
      maxzoom: 14,
    });
    setLoadingBarPercentage(40);

    globalMap.setTerrain({ source: "mapbox-dem", exaggeration: 1.2 });

    globalMap.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: false,
        mapboxgl: mapboxgl,
        placeholder: "Search",
      })
    );
    // globalMap.addControl(new mapboxgl.ScaleControl({position: 'bottom-right'}));
    globalMap.addControl(new mapboxgl.NavigationControl());
    addHomeButton(lng, lat, zoom, mapHome, setMapHome);
    globalMap.addControl(new mapboxgl.FullscreenControl());
    globalMap.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );
  }

  // Adds activated layers on map based on their state (true-false)
  // Run when the map is initalized again (change the style or the site)
  function addMapLayers() {
    if (sitesC) {
      addStationsToMap(getCapabilities);
    }
    if (RSSC) {
      sendRSSRequest();
    }
    if (reflectorsC) {
      sendReflectorsRequest();
    }
    if (meteoC) {
      sendMeteoRequest({ setMeteoC });
    }
    if (geologMap) {
      sendGeologRequest({ setGeologMap });
    }
  }

  return (
    <>
      <div ref={mapContainer} className="map-container" />
      {loggedIn && (
        <MapTools
          mapStyle={mapStyle}
          changeMapStyle={setMapStyle}
          lng={setLng}
          lat={setLat}
          zoom={setZoom}
          pitch={setPitch}
          bearing={setBearing}
          setAvData={setPointData}
          setNewAvData={setnewPointData}
          sChart={setShowChart}
          setDisplayStationData={setDisplayStationData}
          setavPolygon={setavPolygon}
          layer_id={layer_id}
          stationsOnMap={stationsOnMap}
          sitesC={sitesC}
          meteoC={meteoC}
          reflectorsC={reflectorsC}
          setSitesC={setSitesC}
          setReflectorsC={setReflectorsC}
          setMeteoC={setMeteoC}
          setGeodepC={setGeodepC}
          setPointCloudC={setPointCloudC}
          geodepC={geodepC}
          setfirst_LastDate={setfirst_LastDate}
          showChartBtn={showChartBtn}
          setShowChart={setShowChart}
          setShowDataChart={setShowDataChart}
          showDataChart={showDataChart}
          RSSC={RSSC}
          setRSSC={setRSSC}
          geologMap={geologMap}
          setGeologMap={setGeologMap}
          initRes={getCapabilities}
          showDataTabTool={showDataTabTool}
          setLoadingReqPercentage={setLoadingReqPercentage}
          loadingReqPercentage={loadingReqPercentage}
          sendMeteoRequest={sendMeteoRequest}
          sendGeologRequest={sendGeologRequest}
          AOI={AOI}
          loadedData={loadedData}
        />
      )}
      {showChart && (
        <PlotChart
          data={newpointData}
          setShowChart={setShowChart}
          dataDateRange={first_lastDate}
          stationData={displayStationData}
          setNewFirstDate={setNewFirstDate}
          setNewLastDate={setNewLastDate}
          setOrbitOption={setOrbitOption}
        />
      )}
      <Funding />
    </>
  );
};

export { Map, globalMap, displayTLS, handleData, pointcloudsCapabilities };
