import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { getVideoReq, getImgReq } from "./Requests.js";

const GNSSPopup = ({ site_name, capabilities }) => {
  let properties;
  for (let site of capabilities.features) {
    if (site.properties.station === site_name) {
      properties = site.properties;
      break;
    }
  }
  return (
    <>
      <div className='popup-inner-content'>
        <div className='dataset-tab'>
          <table className='popup-cont'>
            <tbody>
              {/* {keys.map((prop, index) => {
                  if (prop !== "bbox" && prop !== "id" && prop !== "datasets") {
                    return (
                      <tr key={index}>
                        <th>{prop} : </th>
                        <td> {properties[prop]}</td>
                      </tr>
                    );
                  }
                })} */}
              <tr>
                <th> Site : </th>
                <td> {properties.station}</td>
              </tr>
              {/* <tr>
                <th> Northing : </th>
                <td> {properties.Northing}</td>
              </tr>
              <tr>
                <th> Easting : </th>
                <td> {properties.Easting}</td>
              </tr>
              <tr>
                <th> Begin Date : </th>
                <td> {properties.beginning_date}</td>
              </tr>
              <tr>
                <th> End Date : </th>
                <td> {properties.ending_date}</td>
              </tr> */}
              <tr className='popup-datasets-tr'>
                <th> Datasets : </th>
                <td>
                  {Object.getOwnPropertyNames(properties.datasets).map(
                    (dataset) => {
                      return properties.datasets[dataset].status ? (
                        <>
                          {dataset.replace("_", " ")}
                          <br></br>
                        </>
                      ) : null;
                    }
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {/* <button
              type='button'
              className='stations-chart-btn'
              onClick={() =>
                setStationsData({
                  properties,
                  setDisplayStationData,
                  setfirst_LastDate,
                  setnewPointData,
                  orbitOption,
                  setShowChart,
                  setStationProperties,
                  setPointData,
                })
              }
            >
              Chart
            </button> */}
        </div>
      </div>
    </>
  );
};

const RSSPopup = ({ rss }) => {
  let keys = Object.keys(rss);
  return (
    <>
      <table className='popup-cont'>
        <tbody>
          {keys.map((prop, index) => {
            let property;
            if (prop === "link") {
              property = (
                <a href={rss[prop]} target='_blank'>
                  event's manual solution
                </a>
              );
            } else {
              property = rss[prop];
            }
            return (
              <tr key={index}>
                <th>{prop} : </th>
                <td> {prop === "pubDate" ? `${property} GMT` : property}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const ReflectorsPopup = (props) => {
  console.log(props);
  const [videoURL, setVideoURL] = useState(null);
  const [videoURLStatus, setVideoURLStatus] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [imgURL, setImgURL] = useState(null);
  const [imgURLStatus, setImgURLStatus] = useState(false);
  const [showImg, setShowImg] = useState(false);

  useEffect(() => {
    async function sendVideoRequest() {
      const response = await getVideoReq(
        props.props.properties.video,
        "reflectors"
      );
      setVideoURL(response);
      setVideoURLStatus(true);
    }
    sendVideoRequest();
  }, []);

  useEffect(() => {
    async function sendImgRequest() {
      const response = await getImgReq(
        props.props.properties.Photo,
        "reflectors"
      );
      setImgURL(response);
      setImgURLStatus(true);
    }
    sendImgRequest();
  }, []);

  function switchToImage() {
    setShowImg(true);
    setShowVideo(false);
  }

  function switchToVideo() {
    setShowVideo(true);
    setShowImg(false);
  }

  return (
    <>
      <div className='popup-inner-content reflectors'>
        <div className='media-tab-title'>
          <span className='media-props'>
            Reflector: {props.props.properties.Site}
          </span>
          <span className='media-props'>
            <br />
            Lon: {props.props.geometry.coordinates[0].toFixed(6)}
          </span>
          <span className='media-props'>
            Lat: {props.props.geometry.coordinates[1].toFixed(6)}
          </span>
        </div>
        <div className='media-tab'>
          <div className='switch-media'>
            <button
              className='media-tab-btns'
              id='switch-video-btn'
              onClick={() => switchToVideo()}
              style={showVideo ? { color: "#275d95" } : { color: "#fff" }}
            >
              <FontAwesomeIcon icon={faVideo} />
            </button>
            <button
              className='media-tab-btns'
              id='switch-img-btn'
              onClick={() => switchToImage()}
              style={showImg ? { color: "#275d95" } : { color: "#fff" }}
            >
              <FontAwesomeIcon icon={faImage} />
            </button>
          </div>
          <div className='reflectors-video-content'>
            {videoURLStatus && showVideo ? (
              <video width='100%' autoPlay muted controls>
                <source src={videoURL} type='video/mp4' />
              </video>
            ) : !videoURLStatus ? (
              <MediaLoader />
            ) : null}
          </div>
          <div className='reflectors-image-content'>
            {imgURLStatus && showImg ? (
              <img width='100%' src={imgURL} />
            ) : !imgURLStatus && showImg ? (
              <MediaLoader />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const MeteoPopup = (props) => {
  console.log(props);
  return (
    <>
      <div className='popup-inner-content meteo'>
        <table className='popup-cont'>
          <tbody>
            {/* {keys.map((prop, index) => {
                  if (prop !== "bbox" && prop !== "id" && prop !== "datasets") {
                    return (
                      <tr key={index}>
                        <th>{prop} : </th>
                        <td> {properties[prop]}</td>
                      </tr>
                    );
                  }
                })} */}
            <tr>
              <th> Station : </th>
              <td> {props.props.properties.Name}</td>
            </tr>
            <tr>
              <th> Latitude : </th>
              <td> {props.props.properties.Lat}</td>
            </tr>
            <tr>
              <th> Longitude : </th>
              <td> {props.props.properties.Lon}</td>
            </tr>
            <tr>
              <th> Link : </th>
              <td>
                {" "}
                <a href={props.props.properties.URL} target='_blank'>
                  {props.props.properties.URL}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const GeologMapTooltip = ({ properties }) => {
  let keys = Object.keys(properties);
  return (
    <>
      <table className='popup-cont'>
        <tbody>
          {keys.map((prop, index) => {
            return (
              <tr key={index}>
                <th>{prop} : </th>
                <td> {properties[prop]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const MediaLoader = () => {
  return (
    <div class='loadingio-spinner-rolling-pqx4z9i4nsh'>
      <div class='ldio-pwpcxr5ke3q'>
        <div></div>
      </div>
    </div>
  );
};

const PointCloudPopup = (props) => {
  console.log(props);
  const [videoURL, setVideoURL] = useState(null);
  const [videoURLStatus, setVideoURLStatus] = useState(false);
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    async function sendVideoRequest() {
      const response = await getVideoReq(
        props.props.video,
        "point-clouds"
      );
      setVideoURL(response);
      setVideoURLStatus(true);
    }
    sendVideoRequest();
  }, []);
  console.log(props);

  return (
    <>
      <div className='popup-inner-content pointclouds' id="point-clouds-popup">
        <div className='media-tab-title'>
          <span className='media-props'>
            Point Cloud of {props.props.site}
          </span>
        </div>
        <div className='media-tab'>
          <div className='reflectors-video-content'>
            {videoURLStatus && showVideo ? (
              <video width='100%' autoPlay muted controls>
                <source src={videoURL} type='video/mp4' />
              </video>
            ) : !videoURLStatus ? (
              <MediaLoader />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export { GNSSPopup, RSSPopup, ReflectorsPopup, MeteoPopup, GeologMapTooltip, PointCloudPopup, MediaLoader };
