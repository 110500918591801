// import { logRoles } from "@testing-library/react";
import React from "react";
import { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import { startOfToday, addDays } from "date-fns";

// import fcm from "./data/chart/fcm.json";
// import sar from "./data/chart/sar.json";
// import gnss from "./data/chart/gnss.json";

const getColor = (context) => {
  let value = context.dataset.data[context.dataIndex];
  return value <= -10
    ? "#f00"
    : value <= -6.7
    ? "#ffac00"
    : value <= -3.3
    ? "#ccff00"
    : value <= 0
    ? "#00ff02"
    : value <= 3.3
    ? "#00ffcc"
    : value <= 6.7
    ? "#00a7ff"
    : value <= 10
    ? "#4141ff"
    : "#0000ff";
};
// Sar Points Chart
const PlotChart = ({
  data,
  setShowChart,
  dataDateRange,
  stationData,
  setNewFirstDate,
  setNewLastDate,
  setOrbitOption,
}) => {
  let dates = Object.getOwnPropertyNames(data);
  let bd = dates[0];
  let ed = dates[dates.length - 2];
  const begin_date =
    bd[0] + bd[1] + bd[2] + bd[3] + "-" + bd[4] + bd[5] + "-" + bd[6] + bd[7];
  const end_date =
    ed[0] + ed[1] + ed[2] + ed[3] + "-" + ed[4] + ed[5] + "-" + ed[6] + ed[7];
  // console.log(begin_date, end_date);

  if (dataDateRange.length === 0) {
    dataDateRange = [dates[0], dates[dates.length -2]]
  }

  let begin_Date = `${dataDateRange[0][0]}${dataDateRange[0][1]}${dataDateRange[0][2]}${dataDateRange[0][3]}-${dataDateRange[0][4]}${dataDateRange[0][5]}-${dataDateRange[0][6]}${dataDateRange[0][7]}`;
  let end_Date = `${dataDateRange[1][0]}${dataDateRange[1][1]}${dataDateRange[1][2]}${dataDateRange[1][3]}-${dataDateRange[1][4]}${dataDateRange[1][5]}-${dataDateRange[1][6]}${dataDateRange[1][7]}`;
  return (
    <>
      <div className="chart-tab">
        <button
          type="button"
          id="close-chart-btn"
          onClick={() => setShowChart(false)}
        >
          ×
        </button>
        <div className="chart-properties">
          {data.mean_disp && (
            <span className="chart-property">
              Mean Disp.: {data.mean_disp.toFixed(1)} mm
            </span>
          )}
          <label
            className="chart-date-input-label"
            id="l-bg"
            htmlFor="beggin-chart-date"
          >
            From Date
          </label>
          <input
            type="date"
            className="chart-date-input"
            name="beggin-chart-date"
            value={begin_date}
            min={begin_Date}
            max={end_Date}
            id="beggin-chart-date"
            onChange={(e) =>
              setNewFirstDate(e.target.value.replaceAll("-", ""))
            }
          />
          <label
            className="chart-date-input-label"
            id="l-ed"
            htmlFor="end-chart-date"
          >
            To Date
          </label>
          <input
            type="date"
            className="chart-date-input"
            name="end-chart-date"
            value={end_date}
            min={begin_Date}
            max={end_Date}
            id="end-chart-date"
            onChange={(e) => setNewLastDate(e.target.value.replaceAll("-", ""))}
          />

          {stationData ? (
            <>
              <label
                className="chart-date-input-label"
                id="l-ed"
                htmlFor="chart-dropdown"
              >
                Orbit
              </label>
              <select
                className="chart-dropdown"
                name="chart-dropdown"
                onChange={(e) => {
                  setOrbitOption(e.target.value);
                }}
              >
                <option value={"Ascending"}>Ascending</option>
                <option value={"Descending"}>Descending</option>
              </select>
            </>
          ) : null}
        </div>
        <div className="chart-container">
          <LineChart type="line" pointData={data} />
        </div>
      </div>
    </>
  );
};

// Datasets Chart
const DataPlotChart = ({ Data, setShowDataChart, site }) => {
  let ascending = false;

  //Keep the dates of all datasets in a list
  const rawDates = Data.map((dataset) =>
    dataset.properties.values.map((mes) => mes.date)
  );

  // Merge the individual lists into a sigle one
  let mergedDates = [];
  rawDates.forEach((dataset) => {
    mergedDates = mergedDates.concat(dataset); // .concat() merges individual arrays into one
  });

  // Sort the Dates
  const sortedDates = mergedDates.sort((a, b) => new Date(a) - new Date(b));
  const labels = sortedDates.filter((date) => date !== null);
  const uniqueLabels = Array.from(new Set(labels));

  const allDatasets = [];
  const [BeginDate, setBeginDate] = useState(uniqueLabels[0]);
  const [EndDate, setEndDate] = useState(uniqueLabels[uniqueLabels.length - 1]);

  Data.forEach((dataset) => {
    if (dataset.properties.Dataset === "GNSS") {
      const gnss_dates = dataset.properties.values.map((obj) => obj.date);
      const gnss_E = dataset.properties.values.map((obj) => obj.E);
      const gnss_N = dataset.properties.values.map((obj) => obj.N);
      const gnss_h = dataset.properties.values.map((obj) => obj.h);
      const gnss_Es = gnss_E[0];
      const gnss_Ns = gnss_N[0];
      const gnss_hs = gnss_h[0];

      const gnss_E_dif = gnss_E.map((val) =>
        Number((val - gnss_Es).toFixed(3))
      );
      const gnss_N_dif = gnss_N.map((val) =>
        Number((val - gnss_Ns).toFixed(3))
      );
      const gnss_h_dif = gnss_h.map((val) =>
        Number((val - gnss_hs).toFixed(3))
      );

      const final_gnss_E = { properties: { Dataset: "GNSS East", values: [] } };
      const final_gnss_N = {
        properties: { Dataset: "GNSS North", values: [] },
      };
      const final_gnss_h = { properties: { Dataset: "GNSS Z", values: [] } };
      for (let i = 0; i < gnss_dates.length; i++) {
        final_gnss_E.properties.values.push({
          value: gnss_E_dif[i] * 1000,
          date: gnss_dates[i],
        });
        final_gnss_N.properties.values.push({
          value: gnss_N_dif[i] * 1000,
          date: gnss_dates[i],
        });
        final_gnss_h.properties.values.push({
          value: gnss_h_dif[i] * 1000,
          date: gnss_dates[i],
        });
      }

      allDatasets.push(final_gnss_E, final_gnss_N, final_gnss_h);
    } else {
      allDatasets.push(dataset);
    }
  });

  // console.log(allDatasets);

  const newDatasets = useRef([]);

  //Structures the list with all the datasets ready to be consumed by the chart
  function structureDatasets() {
    newDatasets.current.length = 0;
    allDatasets.forEach((dataset) => {
      if (dataset.properties.Dataset === "SAR") {
        const values = uniqueLabels.map((date) => {
          for (let mes of dataset.properties.values) {
            if (
              mes.date === date &&
              mes.ascending === true &&
              checkDateWithin(mes.date)
            ) {
              return mes.value;
            }
          }
        });
        newDatasets.current.push({ Dataset: "SAR Ascending", values: values });
      }

      if (dataset.properties.Dataset === "SAR") {
        const values = uniqueLabels.map((date) => {
          for (let mes of dataset.properties.values) {
            if (
              mes.date === date &&
              mes.ascending === false &&
              checkDateWithin(mes.date)
            ) {
              return mes.value;
            }
          }
        });
        newDatasets.current.push({ Dataset: "SAR Descending", values: values });
      }

      if (
        dataset.properties.Dataset === "FCM" ||
        dataset.properties.Dataset === "GNSS East" ||
        dataset.properties.Dataset === "GNSS North" ||
        dataset.properties.Dataset === "GNSS Z"
      ) {
        const values = [];
        uniqueLabels.forEach((date) => {
          const dataPoint = dataset.properties.values.find(
            (mes) => mes.date === date
          ); // .find() returns the first mes that satisfies the current data
          if (dataPoint && checkDateWithin(dataPoint.date))
            values.push(dataPoint.value);
          if (!dataPoint && checkDateWithin(date)) values.push(null);
          // return dataPoint && checkDateWithin(dataPoint.date) ? dataPoint.value : null;
        });
        newDatasets.current.push({
          Dataset: dataset.properties.Dataset,
          values: values,
        });
      }
    });
  }

  structureDatasets();

  useEffect(() => {
    structureDatasets();
  }, [BeginDate, EndDate, allDatasets]);

  function checkDateWithin(date) {
    return new Date(date) >= new Date(BeginDate) &&
      new Date(date) <= new Date(EndDate)
      ? true
      : false;
  }

  function setNewBeginDate(beginDate) {
    console.log(beginDate);
    setBeginDate(beginDate);
  }

  function setNewEndDate(endDate) {
    console.log(endDate);
    setEndDate(endDate);
  }
  return (
    <>
      <div className="chart-tab">
        <button
          type="button"
          id="close-chart-btn"
          onClick={() => setShowDataChart(false)}
        >
          ×
        </button>
        <div className="chart-site"> Site : {site} </div>
        <div className="chart-properties">
          <label
            className="chart-date-input-label"
            id="l-bg"
            htmlFor="beggin-chart-date"
          >
            From Date
          </label>
          <input
            type="date"
            className="chart-date-input"
            name="beggin-chart-date"
            value={BeginDate}
            min={BeginDate}
            max={EndDate}
            id="beggin-chart-date"
            onChange={(e) => setNewBeginDate(e.target.value)}
          />
          <label
            className="chart-date-input-label"
            id="l-ed"
            htmlFor="end-chart-date"
          >
            To Date
          </label>
          <input
            type="date"
            className="chart-date-input"
            name="end-chart-date"
            value={EndDate}
            min={BeginDate}
            max={EndDate}
            id="end-chart-date"
            onChange={(e) => setNewEndDate(e.target.value)}
          />
        </div>
        <div className="chart-container data">
          <DataLineChart
            type="line"
            datasets={newDatasets.current}
            dates={uniqueLabels}
            beginDate={BeginDate}
            endDate={EndDate}
          />
        </div>
      </div>
    </>
  );
};

const DataLineChart = ({ datasets, dates, beginDate, endDate }) => {
  let labels = dates.filter(
    (date) =>
      new Date(date) > new Date(beginDate) && new Date(date) < new Date(endDate)
  );
  let chartData = {
    labels: labels,
    datasets: [],
  };

  const setColor = (dataset) => {
    if (dataset === "SAR Ascending") return "#f00"; //"#FF666D"; //"#FF363F"
    if (dataset === "SAR Descending") return "#ae00ff"; //"#FF666D"; //"#FF363F"
    if (dataset === "GNSS East") return "#ccff00"; //"#FFFD69"; //"#FFFC36"
    if (dataset === "GNSS North") return "#ffac00"; //"#7DE7FF"; //"#36DAFF"
    if (dataset === "GNSS Z") return "#00ffcc"; //"#FF6BAE"; //"#FF3691"
    if (dataset === "FCM") return "#4141ff"; //"#6E88FF"; //"#365AFF"
  };

  datasets.map((dataset) => {
    chartData.datasets.push({
      label: dataset.Dataset,
      data: dataset.values,
      backgroundColor: "rgba(255, 99, 132, 0)",
      borderColor: (context) => setColor(context.dataset.label),
      borderWidth: 1.5,
      tension: 0,
      pointRadius: 1.2,
      // fill: "origin",
      pointBackgroundColor: (context) => setColor(context.dataset.label),
    });
  });

  return (
    <div className="chart-container">
      <Line
        data={chartData}
        options={{
          interaction: {
            intersect: false,
            mode: "index",
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: false,
              // text: "DSS Scores",
              color: "#fff",
            },
            aspectRatio: 1,
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                color: "#fff",
                font: {
                  family: "'Montserrat', sans-serif",
                  size: 12,
                  weight: "bold",
                },
              },
              position: "top",
            },
            tooltip: {
              usePointStyle: true,
              title: false,
              intersect: false,
              position: "nearest",
              rtl: true,
              backgroundColor: "#275d95",
              fontSize: 8,
              opacity: 1,
              font: {
                // family: "Helvetica Neue",
                color: "#a75fdc",
                size: 14,
              },
              callbacks: {
                label: (context) => {
                  return `${context.dataset.label}: ${context.raw.toFixed(
                    1
                  )} mm`;
                },
                title: (context) => {
                  return `Displacement | ${context[0].label}`;
                },
              },
            },
          },
          scales: {
            y: {
              grid: {
                color: (context) => {
                  if (context.tick.value === 0) return "#275d95";
                  return "#275d9524";
                },
                lineWidth: (context) => {
                  if (context.tick.value === 0) return 2;
                  return 1;
                },
              },
              title: {
                display: true,
                text: "Displacement (mm)",
                color: "white",
              },
              ticks: {
                beginAtZero: true,
                color: "white",
              },
            },
            x: {
              title: {
                display: true,
                text: "Dates",
                color: "white",
              },
              ticks: {
                font: {
                  // family: "Helvetica Neue",
                  size: 10,
                },
                color: "white",
                maxRotation: 30,
              },
            },
          },
          animation: {
            // duration: 1
          },
        }}
      />
    </div>
  );
};

const LineChart = ({ pointData }) => {
  const keys = Object.keys(pointData);

  const newObject = keys.slice(0, keys.length - 5).reduce((obj, key) => {
    obj[key] = pointData[key];
    return obj;
  }, {});

  let labels = Object.getOwnPropertyNames(newObject).map((k) => {
    return k[0] + k[1] + k[2] + k[3] + "-" + k[4] + k[5] + "-" + k[6] + k[7];
  });

  let chartData = {
    labels: labels,
    datasets: [
      {
        label: "Displacement (mm)",
        data: Object.values(newObject),
        backgroundColor: "rgba(255, 99, 132, 0)",
        borderColor: "#242424",
        borderWidth: 0.5,
        tension: 0,
        pointRadius: 2.5,
        fill: "origin",
        pointBackgroundColor: (context) => getColor(context),
      },
    ],
  };

  return (
    <div className="chart-container">
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: false,
              // text: "DSS Scores",
              color: "#fff",
            },
            aspectRatio: 1,
            legend: {
              display: false,
              labels: {
                usePointStyle: true,
                color: "#fff",
                font: {
                  family: "'Montserrat', sans-serif",
                  size: 12,
                  weight: "bold",
                },
              },
              position: "top",
            },
            tooltip: {
              usePointStyle: true,
              title: false,
              intersect: false,
              position: "nearest",
              rtl: true,
              backgroundColor: "#275d95",
              fontSize: 8,
              opacity: 1,
              font: {
                // family: "Helvetica Neue",
                color: "#a75fdc",
                size: 14,
              },
              // callbacks: {
              //   label: (context) => {
              //     return `${context.label[0]}${context.label[1]}${context.label[2]}${context.label[3]}/${context.label[4]}${context.label[5]}/${context.label[6]}${context.label[7]} : ${context.formattedValue} mm/year`;
              //   },
              //   title: () => null,
              // },
            },
          },
          scales: {
            y: {
              grid: {
                color: (context) => {
                  if (context.tick.value === 0) return "#275d95";
                  return "#275d9524";
                },
                lineWidth: (context) => {
                  if (context.tick.value === 0) return 2;
                  return 1;
                },
              },
              title: {
                display: true,
                text: "Displacement (mm)",
                color: "white",
              },
              ticks: {
                beginAtZero: true,
                color: "white",
              },
            },
            x: {
              // type: "timeseries",
              // time: {
              //   unit: "millisecond",
              //   displayFormats: {
              //     quarter: "YYYY MM DD",
              //   },
              // },
              title: {
                display: true,
                text: "Dates",
                color: "white",
              },
              ticks: {
                font: {
                  // family: "Helvetica Neue",
                  size: 10,
                },
                color: "white",
                maxRotation: 30,
              },
            },
          },
          animation: {
            // duration: 1
          },
        }}
      />
    </div>
  );
};

export { LineChart, PlotChart, DataPlotChart };
