import axios from "axios";

// const host = "http://localhost:5000"; // active only in dev mode
const host = "";

async function getInitgetCapabilitiesReq() {
  const url = `${host}/getCapabilities`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

async function getDataReq(args, setLoadingReqPercentage) {
  let url = `${host}/getData` + args;
  // console.log(url);
  setLoadingReqPercentage(40);
  try {
    const response = await axios({
      url: url,
      method: "GET",
      onDownloadProgress: (progressEvent) => {
        setLoadingReqPercentage(75);
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setLoadingReqPercentage(20 + percentCompleted * 0.7);
      },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

async function getPointCloudReq(site, setLoadingReqPercentage) {
  const url = `${host}/pointcloud/` + site;
  console.log(url);
  try {
    const response = await axios({
      url: url,
      method: "GET",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setLoadingReqPercentage(percentCompleted * 0.8);
      },
    });
    let csv = response.data.split("\r\n");
    setLoadingReqPercentage(100);
    // console.log(csv[0]);
    return csv;
  } catch (error) {
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

async function getLocalDataReq(dataset) {
  const url = `${host}/${dataset}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    alert(`Something went wrong with the handling of ${dataset}`)
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

const videos = [];

async function getVideoReq(title, dataset) {
  const url = `${host}/video/${dataset}/${title}`;
  if (Object.keys(videos).includes(title)) return videos[title];
  try {
    return await axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        let videoURL = (window.URL || window.webkitURL).createObjectURL(
          new Blob([response.data])
        );
        videos[`${title}`] = videoURL;
        console.log(videoURL);
        return videoURL;
      });
  } catch (error) {
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

const images = [];

async function getImgReq(title, dataset) {
  const url = `${host}/image/${dataset}/${title}`;
  // console.log(images);
  if (Object.keys(images).includes(title)) return images[title];
  try {
    return await axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        let imgURL = (window.URL || window.webkitURL).createObjectURL(
          new Blob([response.data])
        );
        images[`${title}`] = imgURL;
        // console.log(imgURL);
        return imgURL;
      });
  } catch (error) {
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

async function getAvReq(polygon) {
  const url = `${host}/averagetool`;
  try {
    const response = await axios.post(url, polygon);
    return response.data;
  } catch (error) {
    if (error.response.status !== 401) {
      console.error(error);
      return null;
    }
  }
}

async function login(
  username,
  password,
  setFailedLogin,
  setFailedRequest,
  setLoggedIn,
  setDisplayChecked
) {
  axios
    .post(`${host}/login`, {
      username,
      password,
    })
    .then(() => {
      setDisplayChecked(true);
      setTimeout(() => {
        setLoggedIn(true);
      }, 2500);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        setFailedLogin(true);
      } else {
        setFailedRequest(true);
      }
    });
}

async function checkLogIn(setLoggedIn, setDisplayChecked) {
  axios
    .get(`${host}/checkLogin`)
    .then(() => {
      setDisplayChecked(true);
      setTimeout(() => {
        setLoggedIn(true);
      }, 2500);
    })
    .catch((err) => {
      if (err.response.status === 401) setLoggedIn(false);
      else console.log(err);
    });
}
export {
  getInitgetCapabilitiesReq,
  getDataReq,
  getPointCloudReq,
  getVideoReq,
  getImgReq,
  getLocalDataReq,
  getAvReq,
  login,
  checkLogIn,
};
