import logo from "./img/proion_logo.png";

const Loading = ({ percentage }) => {
  return (
    <div id="loading">
      <img className="loading-logo-image" src={logo}></img>
      <div className="loading-bar-placeholder">
        <div className="loading-bar" style={{ width: percentage + "%" }}></div>
      </div>
    </div>
  );
};

const SecondaryLoadind = ({loadingReqPercentage}) => {
  return (
    <>
      {(loadingReqPercentage > 0 && loadingReqPercentage < 100) && (
        <>
        <div className="secondaryLoading-bar" style={{ width: `${loadingReqPercentage}%` }}></div>
        <div className="secondaryLoading-bar-line"></div>
        </>
      )}
    </>
  );
};

export { Loading, SecondaryLoadind };
