import { useEffect, useState } from "react";
// import res from "./data/requests/form_res.json";
import { getDataReq, login } from "./Requests.js";
import { globalMap, handleData } from "./Map.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrawPolygon,
  faMinus,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import * as turf from "@turf/turf";

const observationList = [];
let BBOXonMap = false;
let drawBBOX = false;
// let initialTab = true;

let CircleonMap = false;
let drawCircle = false;

const formProperties = {
  Site: null,
  Properties: [],
  BegDate: null,
  EndDate: null,
  Aggregation: {
    Without: true,
    AggValue: null,
    AggDuration: "days",
    AggFunction: "AVG",
  },
  ResVal: {
    Without: true,
    DatasetsList: [],
  },
  BBOX: {
    Without: true,
    UpperX: null,
    UpperY: null,
    LowerX: null,
    LowerY: null,
  },
  Distance: {
    Without: true,
    CenterX: null,
    CenterY: null,
    DistValue: null,
  },
};

const Form = (props) => {
  // console.log(props.getCapabilities);
  const [res, setRes] = useState(props.getCapabilities);
  const [selectedSt, setSelectedSt] = useState(-1);
  const [obsListStatus, setObsListStatus] = useState(false);
  const [obsOptions, setObsOptions] = useState([]);
  const [aggregationStatus, setAggregationStatus] = useState(false);
  const [resValuesStatus, setResValuesStatus] = useState(false);
  const [initialTab, setInitialTab] = useState(true);

  const [cursorBox, setCursorBox] = useState({
    show: false,
    x: null,
    y: null,
    content: null,
  });

  // Time vars
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedBeginDate, setSelectedBeginDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  //BBOX vars
  const [BBOXStatus, setBBOXStatus] = useState(false);
  const [BBOXcoords, setBBOXcoords] = useState({});
  const [BBOXsmallTab, setBBOXsmallTab] = useState(false);
  let BBOXclicks = 0;

  //Distance vars
  const [distanceStatus, setDistanceStatus] = useState(false);
  const [CenterCoords, setCenterCoords] = useState({});
  const [distancesmallTab, setDistancesmallTab] = useState(false);

  // For the identification of begin and end date of the dataset for the selected site
  useEffect(() => {
    // initially set null the dates for date input fields
    setBeginDate(null);
    setEndDate(null);
    let temp_beginDate = null;
    let temp_endDate = null;
    // Parse the getCapabilities respone
    res.features.map((feature) => {
      if (feature.properties.id === selectedSt) {
        Object.getOwnPropertyNames(feature.properties.datasets).map(
          (dataset) => {
            // if the dataset exists in the list of selected observations (obsOptions) calculate the begin and end date
            obsOptions.forEach((obs) => {
              if (obs.includes(dataset)) {
                if (temp_beginDate === null) {
                  temp_beginDate =
                    feature.properties.datasets[dataset].begin_date;
                  temp_endDate = feature.properties.datasets[dataset].end_date;
                } else {
                  if (
                    Date.parse(
                      feature.properties.datasets[dataset].begin_date
                    ) > Date.parse(temp_beginDate)
                  ) {
                    temp_beginDate =
                      feature.properties.datasets[dataset].begin_date;
                  }
                  if (
                    Date.parse(feature.properties.datasets[dataset].end_date) <
                    Date.parse(temp_endDate)
                  ) {
                    temp_endDate =
                      feature.properties.datasets[dataset].end_date;
                  }
                }
              }
            });
          }
        );
      }
    });
    setBeginDate(temp_beginDate);
    setEndDate(temp_endDate);

    //Set default begin and end date in request JSON, based on the range of Dates
    formProperties.BegDate = temp_beginDate;
    formProperties.EndDate = temp_endDate;

    // if (
    //   Date.parse(temp_beginDate) > Date.parse(selectedBeginDate) ||
    //   Date.parse(temp_endDate) < Date.parse(selectedBeginDate)
    // ) {
    //   setSelectedBeginDate(temp_beginDate);
    // }
    // if (
    //   Date.parse(temp_endDate) < Date.parse(selectedEndDate) ||
    //   Date.parse(temp_beginDate) > Date.parse(selectedEndDate)
    // ) {
    //   setSelectedEndDate(temp_endDate);
    // }
  }, [selectedSt, obsOptions]);

  //Set begin and end date to formProperties json
  useEffect(() => {
    if (selectedBeginDate !== null) {
      formProperties.BegDate = selectedBeginDate;
    }
    if (selectedEndDate !== null) {
      formProperties.EndDate = selectedEndDate;
    }
  }, [selectedBeginDate, selectedEndDate]);

  let Circleclicks = 0;
  const clearCheckList = () => {
    observationList.length = 0;
    setObsOptions([]);
    setObsListStatus(false);
  };

  const updateCheckedList = (id) => {
    const gnssObs = ["GNSS_N", "GNSS_E", "GNSS_h"];
    if (id === "GNSS") {
      gnssObs.forEach((obsID) => {
        main(obsID);
      });
    } else {
      main(id);
    }
    function main(id) {
      if (observationList.includes(id)) {
        const index = observationList.indexOf(id);
        observationList.splice(index, 1);
        if (observationList.length <= 0) {
          setObsListStatus(false);
        }
      } else {
        observationList.push(id);
        setObsListStatus(true);
      }
    }

    const options = observationList.map((obs) => obs);
    setObsOptions(options);
    formProperties.Properties = options;
    // console.log(observationList);
  };

  const DrawBBOX = () => {
    // Zoom to the selected Site
    for (let station of props.getCapabilities.features){
      if (station.properties.station === formProperties.Site) {
        globalMap.flyTo({
          center: station.geometry.coordinates,
          zoom: 14,
        });
        break;
      }
    }

    // Show small tool tab
    setBBOXsmallTab(true);
    // Hide form tab
    showHideSearchTab(false, true);
    drawBBOX = true;
    setCenterCoords({});
    // Deactivate Distance Filter
    if (!formProperties.Distance.Without) {
      setDistanceStatus(false);
      drawCircle = false;
      formProperties.Distance.Without = true;
      eraseCircle();
    }
    // --

    let firstClickCoords = {};
    let secondClickCoords = {};
    globalMap.getCanvas().style.cursor = "crosshair";
    globalMap.on("click", (e) => {
      if (drawBBOX) {
        if (BBOXclicks === 0) {
          firstClickCoords = e.lngLat;
          // With the 1st click upper and lower corner get the same coords
          setBBOXcoords({
            UpperX: e.lngLat.lng.toFixed(6),
            UpperY: e.lngLat.lat.toFixed(6),
            LowerX: e.lngLat.lng.toFixed(6),
            LowerY: e.lngLat.lat.toFixed(6),
          });
          BBOXclicks = 1;
        } else if (BBOXclicks === 1) {
          setBBOXsmallTab(false);
          showHideSearchTab(true, true);
          secondClickCoords = e.lngLat;
          BBOXclicks = 0;
          findMinMax(e.lngLat, firstClickCoords);
          globalMap.getCanvas().style.cursor = "";
        }
      }
    });
    // When the cursor move around the map the bbox coordinates will be updated until the second click
    globalMap.on("mousemove", (e) => {
      if (drawBBOX) {
        let [mouseX, mouseY] = [e.point.x, e.point.y];
        setCursorBox({
          show: true,
          x: mouseX,
          y: mouseY,
          content: "Left Click to draw the BBOX",
        });
        if (BBOXclicks === 1) {
          let intBBOXcoords = findMinMax(e.lngLat, firstClickCoords);
          DrawBBOXOnMap(intBBOXcoords);
          formProperties.BBOX.UpperX = intBBOXcoords.UpperX;
          formProperties.BBOX.UpperY = intBBOXcoords.UpperY;
          formProperties.BBOX.LowerX = intBBOXcoords.LowerX;
          formProperties.BBOX.LowerY = intBBOXcoords.LowerY;
          setCursorBox({
            show: true,
            x: mouseX,
            y: mouseY,
            content: "Left Click to determine the BBOX",
          });
        }
      }
    });

    const findMinMax = (CursorCoords, ClickCoords) => {
      // console.log(CursorCoords, ClickCoords)

      let maxLng = ClickCoords.lng;
      let minLng = ClickCoords.lng;
      let maxLat = ClickCoords.lat;
      let minLat = ClickCoords.lat;

      if (CursorCoords.lng > ClickCoords.lng) {
        maxLng = CursorCoords.lng;
      }
      if (CursorCoords.lng < ClickCoords.lng) {
        minLng = CursorCoords.lng;
      }
      if (CursorCoords.lat > ClickCoords.lat) {
        maxLat = CursorCoords.lat;
      }
      if (CursorCoords.lat < ClickCoords.lat) {
        minLat = CursorCoords.lat;
      }
      let intBBOXcoords = {
        UpperX: minLng.toFixed(6),
        UpperY: maxLat.toFixed(6),
        LowerX: maxLng.toFixed(6),
        LowerY: minLat.toFixed(6),
      };
      //set BBOX coords
      setBBOXcoords(intBBOXcoords);
      return intBBOXcoords;
    };
  };
  const DrawBBOXOnMap = (intBBOXcoords) => {
    const BBOXpolygon = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: [
                [
                  [intBBOXcoords.UpperX, intBBOXcoords.UpperY],
                  [intBBOXcoords.LowerX, intBBOXcoords.UpperY],
                  [intBBOXcoords.LowerX, intBBOXcoords.LowerY],
                  [intBBOXcoords.UpperX, intBBOXcoords.LowerY],
                  [intBBOXcoords.UpperX, intBBOXcoords.UpperY],
                ],
              ],
              type: "Polygon",
            },
          },
        ],
      },
    };

    if (BBOXonMap) {
      globalMap.getSource("BBOX-polygon").setData(BBOXpolygon.data);
    } else {
      BBOXonMap = true;
      globalMap.addSource("BBOX-polygon", BBOXpolygon);

      globalMap.addLayer({
        id: "BBOX-polygon",
        type: "fill",
        source: "BBOX-polygon", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#fff",
          "fill-opacity": 0.1,
        },
      });
      // Outline around the polygon.
      globalMap.addLayer({
        id: "BBOX-outline",
        type: "line",
        source: "BBOX-polygon",
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 1.8,
          "line-dasharray": [2, 1],
        },
      });
    }
  };

  const DrawCircle = () => {
    setDistancesmallTab(true);
    showHideSearchTab(false, true);
    drawCircle = true;
    setBBOXcoords({});
    // Deactivate Distance Filter
    if (!formProperties.BBOX.Without) {
      setBBOXStatus(false);
      drawBBOX = false;
      formProperties.BBOX.Without = true;
      eraseBBOX();
    }
    // --


    let firstClickCoords = {};
    let secondClickCoords = {};
    globalMap.getCanvas().style.cursor = "crosshair";
    globalMap.on("click", (e) => {
      if (drawCircle) {
        // console.log(`A click event has occurred at ${e.lngLat}`);
        if (Circleclicks === 0) {
          firstClickCoords = e.lngLat;
          // With the 1st click upper and lower corner get the same coords
          setCenterCoords({
            CenterX: e.lngLat.lng.toFixed(6),
            CenterY: e.lngLat.lat.toFixed(6),
            Distance: 0,
          });
          Circleclicks = 1;
        } else if (Circleclicks === 1) {
          setDistancesmallTab(false);
          showHideSearchTab(true, true);
          secondClickCoords = e.lngLat;
          Circleclicks = 0;
          calDistance(e.lngLat, firstClickCoords);
          globalMap.getCanvas().style.cursor = "";
        }
      }
    });
    // When the cursor move around the map the Circle properties will be updated until the second click
    globalMap.on("mousemove", (e) => {
      if (drawCircle) {
        let [mouseX, mouseY] = [e.point.x, e.point.y];
        setCursorBox({
          show: true,
          x: mouseX,
          y: mouseY,
          content: "Left Click to draw the Circle",
        });
        if (Circleclicks === 1) {
          let intCentercoords = calDistance(e.lngLat, firstClickCoords);
          DrawCircleOnMap(intCentercoords);
          setCursorBox({
            show: true,
            x: mouseX,
            y: mouseY,
            content: "Left Click to determine the Circle",
          });
        }
      }
    });

    const calDistance = (point, center) => {
      const from = turf.point([center.lng, center.lat]);
      const to = turf.point([point.lng, point.lat]);
      const options = { units: "kilometers" };
      const distance = turf.distance(from, to, options);
      let circleProperties = {
        CenterX: center.lng.toFixed(6),
        CenterY: center.lat.toFixed(6),
        Distance: distance.toFixed(6),
      };

      formProperties.Distance.CenterX = circleProperties.CenterX;
      formProperties.Distance.CenterY = circleProperties.CenterY;
      formProperties.Distance.DistValue = circleProperties.Distance;
      setCenterCoords(circleProperties);
      return circleProperties;
    };
  };

  const DrawCircleOnMap = (intCentercoords) => {
    const center = [intCentercoords.CenterX, intCentercoords.CenterY];
    const radius = intCentercoords.Distance;
    const options = {
      steps: 100,
      units: "kilometers",
      properties: { foo: "bar" },
    };
    const circle = turf.circle(center, radius, options);
    const Circlepolygon = {
      type: "geojson",
      data: circle,
    };

    if (CircleonMap) {
      globalMap.getSource("Circle-polygon").setData(Circlepolygon.data);
    } else {
      CircleonMap = true;
      globalMap.addSource("Circle-polygon", Circlepolygon);

      globalMap.addLayer({
        id: "Circle-polygon",
        type: "fill",
        source: "Circle-polygon", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#fff",
          "fill-opacity": 0.1,
        },
      });
      // Outline around the polygon.
      globalMap.addLayer({
        id: "Circle-outline",
        type: "line",
        source: "Circle-polygon",
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 1.8,
          "line-dasharray": [2, 1],
        },
      });
    }
  };

  const eraseBBOX = () => {
    drawBBOX = false;
    DrawBBOXOnMap({});
    setBBOXcoords({});
    globalMap.removeLayer("BBOX-polygon");
    globalMap.removeLayer("BBOX-outline");
    globalMap.removeSource("BBOX-polygon");
    BBOXclicks = 0;
    BBOXonMap = false;
    formProperties.BBOX.Without = true;
  };

  const eraseCircle = () => {
    drawCircle = false;
    // DrawCircleOnMap({});
    setCenterCoords({});
    globalMap.removeLayer("Circle-polygon");
    globalMap.removeLayer("Circle-outline");
    globalMap.removeSource("Circle-polygon");
    Circleclicks = 0;
    CircleonMap = false;
    formProperties.Distance.Without = true;
  };

  const addResValuesDataset = (
    dataset,
    condition = "PropertyIsEqualTo",
    value = null
  ) => {
    if (value !== null && value !== "") {
      setAggregationStatus(false);
      formProperties.Aggregation.Without = true;
    }
    let exist = false;
    formProperties.ResVal.DatasetsList.forEach((e) => {
      if (e.ResProperty === dataset) {
        if (condition !== "PropertyIsEqualTo") e.ResCondition = condition;
        if (value !== null) e.ResValue = value;
        exist = true;
      }
    });
    if (!exist) {
      formProperties.ResVal.DatasetsList.push({
        ResProperty: dataset,
        ResCondition: condition,
        ResValue: value,
      });
    }
  };

  const addAggregation = (value) => {
    formProperties.Aggregation.AggValue = value;
    if (value !== null && value !== "") {
      setResValuesStatus(false);
      formProperties.ResVal.Without = true;
    }
  };

  const calcPrevMonth = (date) => {
    if (date === null) return;
    date = new Date(date);
    date.setMonth(date.getMonth() - 3); // Subtract 1 months
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    formProperties.BegDate = formattedDate;
    return formattedDate;
  };

  return (
    <>
      <div className="glass-background" id="main-form-glass-background"></div>
      <div className="main-form" id="main-form">
        <div className="main-form-header">
          {initialTab
            ? "Welcome to PROION project Web GIS Platform! Apply your filters to start the monitoring."
            : "PROION Data Search Tab"}
          {!initialTab && (
            <button
              type="button"
              id="close-filter-search-btn"
              onClick={() => {
                showHideSearchTab(false);
              }}
            >
              ×
            </button>
          )}
        </div>
        <div className="main-form-content">
          <div className="main-form-content-content">
            <div className="form-filter">
              <div className="form-headers" id="sites-header">
                Sites
              </div>
              <div className="form-groups" id="sites-group">
                {res.features.map((feature, key) => {
                  return (
                    <label
                      htmlFor={feature.properties.id}
                      className="form-labels"
                      key={key}
                    >
                      <input
                        type="radio"
                        name="sites"
                        value={feature.properties.id}
                        className="sites-radios"
                        id={feature.properties.id}
                        onChange={() => {
                          formProperties.Site = feature.properties.station;
                          setSelectedSt(feature.properties.id);
                          clearCheckList();
                          // console.log(selectedSt);
                        }}
                        //   checked={(feature.properties.id == selectedSt) ? true : false}
                      />
                      {feature.properties.station}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="form-filter">
              <div className="form-headers" id="form-date-header">
                Time Period
              </div>
              <div className="form-groups" id="form-date-group">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="begin-form-date">Begin Date</label>
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-date-input"
                          name="end-form-date"
                          min={beginDate}
                          max={endDate}
                          value={
                            selectedBeginDate !== null
                              ? selectedBeginDate
                              : calcPrevMonth(endDate)
                          }
                          id="begin-form-date"
                          onChange={(e) => {
                            setSelectedBeginDate(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="end-form-date">End Date</label>
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-date-input"
                          name="end-form-date"
                          min={beginDate}
                          max={endDate}
                          value={
                            selectedEndDate !== null ? selectedEndDate : endDate
                          }
                          id="end-form-date"
                          onChange={(e) => {
                            setSelectedEndDate(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="form-headers" id="form-aggregation-header">
                <span
                  className="form-plus-icon"
                  onClick={(e) => {
                    setAggregationStatus(!aggregationStatus);
                    formProperties.Aggregation.Without =
                      !formProperties.Aggregation.Without;
                    setResValuesStatus(false);
                    formProperties.ResVal.Without = true;
                  }}
                >
                  {!aggregationStatus ? (
                    <FontAwesomeIcon icon={faPlus} />
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>{" "}
                Time Aggregation
              </div>
              <div className="form-groups" id="form-aggregation-group">
                <table
                  style={!aggregationStatus ? { opacity: 0.4 } : { opacity: 1 }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="begin-form-date">Time Interval</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          id="aggregation-value"
                          placeholder="00"
                          onChange={(e) => addAggregation(e.target.value)}
                          disabled={!aggregationStatus}
                        />
                      </td>
                      <td>
                        <select
                          id="aggregation-duration"
                          onChange={(e) =>
                            (formProperties.Aggregation.AggDuration =
                              e.target.value)
                          }
                          disabled={!aggregationStatus}
                        >
                          <option value="days">days</option>
                          <option value="months">months</option>
                          <option value="years">years</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="begin-form-date">Function</label>
                      </td>
                      <td>
                        <select
                          id="aggregation-function"
                          onChange={(e) =>
                            (formProperties.Aggregation.AggFunction =
                              e.target.value)
                          }
                          disabled={!aggregationStatus}
                        >
                          <option value="AVG">AVG</option>
                          <option value="SUM">SUM</option>
                          <option value="MAX">MAX</option>
                          <option value="MIN">MIN</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="form-filter">
              <div className="form-headers" id="form-resvalues-header">
                <span
                  className="form-plus-icon"
                  onClick={(e) => {
                    setResValuesStatus(!resValuesStatus);
                    formProperties.ResVal.Without =
                      !formProperties.ResVal.Without;
                    setAggregationStatus(false);
                    formProperties.Aggregation.Without = true;
                  }}
                >
                  {!resValuesStatus ? (
                    <FontAwesomeIcon icon={faPlus} />
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>{" "}
                Result Values (Displacement)
              </div>
              <div
                className="form-groups"
                id="form-resvalues-group"
                style={!resValuesStatus ? { opacity: 0.4 } : { opacity: 1 }}
              >
                {!obsListStatus ? (
                  "Please select observed datasets first."
                ) : (
                  <>
                    <table
                      style={
                        !resValuesStatus ? { opacity: 0.4 } : { opacity: 1 }
                      }
                    >
                      <tbody>
                        <tr>
                          <td className="resvalues-labels">&nbsp;Dataset</td>
                          <td className="resvalues-labels">&nbsp;Condition</td>
                          <td className="resvalues-labels">&nbsp;Value </td>
                        </tr>
                        {obsOptions.map((obs, key) => {
                          return (
                            <tr>
                              <td>
                                <span className="resVal-dataset-name">
                                  {obs.replace("_", " ")}
                                </span>
                              </td>
                              <td>
                                <select
                                  id="resvalues-condition"
                                  onChange={(e) =>
                                    addResValuesDataset(
                                      obs,
                                      e.target.value,
                                      null
                                    )
                                  }
                                  disabled={!resValuesStatus}
                                >
                                  <option value="PropertyIsEqualTo">=</option>
                                  <option value="PropertyIsNotEqualTo">
                                    !=
                                  </option>
                                  <option value="PropertyIsLessThan">
                                    {" "}
                                    &lt;{" "}
                                  </option>
                                  <option value="PropertyIsLessThanOrEqualTo">
                                    &lt;=
                                  </option>
                                  <option value="PropertyIsGreaterThan">
                                    {" "}
                                    &gt;{" "}
                                  </option>
                                  <option value="PropertyIsGreaterThanOrEqualTo">
                                    {" "}
                                    &gt;={" "}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="resvalues-value"
                                  placeholder="00"
                                  onChange={(e) =>
                                    addResValuesDataset(
                                      obs,
                                      "PropertyIsEqualTo",
                                      e.target.value
                                    )
                                  }
                                  disabled={!resValuesStatus}
                                />
                              </td>
                              <td>mm</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
            <div className="form-filter">
              <div className="form-headers" id="observations-header">
                Observed Datasets
              </div>
              <div className="form-groups" id="observations-group">
                {selectedSt !== -1
                  ? res.features.map((feature) => {
                      if (feature.properties.id === selectedSt) {
                        return Object.getOwnPropertyNames(
                          feature.properties.datasets
                        ).map((dataset, key) => {
                          return (
                            <>
                              <label
                                htmlFor={dataset}
                                className="form-labels"
                                style={
                                  !feature.properties.datasets[dataset].status
                                    ? { opacity: 0.5 }
                                    : { opacity: 1 }
                                }
                              >
                                <input
                                  type="checkbox"
                                  name="observations"
                                  value={dataset}
                                  className="observation-checkboxes"
                                  id={dataset}
                                  key={key}
                                  onChange={() => updateCheckedList(dataset)}
                                  disabled={
                                    !feature.properties.datasets[dataset].status
                                  }
                                />
                                {dataset.replace("_", " ")}
                              </label>

                              {feature.properties.datasets[dataset].status && (
                                <div className="datasets-obs-period">
                                  {" "}
                                  {
                                    feature.properties.datasets[dataset]
                                      .begin_date
                                  }{" "}
                                  /{" "}
                                  {
                                    feature.properties.datasets[dataset]
                                      .end_date
                                  }{" "}
                                </div>
                              )}
                            </>
                          );
                        });
                      }
                    })
                  : "Please select a Site first."}
              </div>
            </div>
            <div className="form-filter" id="form-filter-BBOX">
              <div className="form-headers" id="form-bbox-header">
                <span
                  className="form-plus-icon"
                  onClick={(e) => {
                    setBBOXStatus(!BBOXStatus);
                    formProperties.BBOX.Without = !formProperties.BBOX.Without;
                  }}
                >
                  {!BBOXStatus ? (
                    <FontAwesomeIcon icon={faPlus} />
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>{" "}
                Area BBOX
              </div>
              <div className="form-groups" id="form-bbox-group">
                <table style={!BBOXStatus ? { opacity: 0.4 } : { opacity: 1 }}>
                  <tbody>
                    <tr>
                      <td>Upper Corner</td>
                    </tr>
                    <tr>
                      <td>
                        X:{" "}
                        <input
                          type="text"
                          className="bbox-values"
                          id="bbox-values-upper-x"
                          placeholder="e.g. 21.184096"
                          value={BBOXcoords.UpperX ? BBOXcoords.UpperX : ""}
                          onChange={(e) =>
                            (formProperties.BBOX.UpperX = e.target.value)
                          }
                          disabled={!BBOXStatus}
                        />
                      </td>
                      <td>
                        &nbsp;Y:{" "}
                        <input
                          type="text"
                          className="bbox-values"
                          id="bbox-values-upper-y"
                          placeholder="e.g. 39.353138"
                          value={BBOXcoords.UpperY ? BBOXcoords.UpperY : ""}
                          onChange={(e) =>
                            (formProperties.BBOX.UpperY = e.target.value)
                          }
                          disabled={!BBOXStatus}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Lower Corner</td>
                    </tr>
                    <tr>
                      <td>
                        X:{" "}
                        <input
                          type="text"
                          className="bbox-values"
                          id="bbox-values-lower-x"
                          placeholder="e.g. 21.182196"
                          value={BBOXcoords.LowerX ? BBOXcoords.LowerX : ""}
                          onChange={(e) =>
                            (formProperties.BBOX.LowerX = e.target.value)
                          }
                          disabled={!BBOXStatus}
                        />
                      </td>
                      <td>
                        &nbsp;Y:{" "}
                        <input
                          type="text"
                          className="bbox-values"
                          id="bbox-values-lower-y"
                          placeholder="e.g. 39.423138"
                          value={BBOXcoords.LowerY ? BBOXcoords.LowerY : ""}
                          onChange={(e) =>
                            (formProperties.BBOX.LowerY = e.target.value)
                          }
                          disabled={!BBOXStatus}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span>
                  <button
                    className="form-btns"
                    id="draw-form-bbox"
                    onClick={() => DrawBBOX()}
                    disabled={!BBOXStatus}
                    style={!BBOXStatus ? { opacity: 0.4 } : { opacity: 1 }}
                  >
                    <FontAwesomeIcon icon={faDrawPolygon} /> Draw on Map
                  </button>
                  <button
                    className="form-btns"
                    id="clear-form-bbox"
                    onClick={() => eraseBBOX()}
                    disabled={!BBOXStatus}
                    style={!BBOXStatus ? { opacity: 0.4 } : { opacity: 1 }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </span>
              </div>
            </div>
            <div className="form-filter">
              <div className="form-headers" id="form-distance-header">
                <span
                  className="form-plus-icon"
                  onClick={(e) => {
                    setDistanceStatus(!distanceStatus);
                    formProperties.Distance.Without =
                      !formProperties.Distance.Without;
                  }}
                >
                  {!distanceStatus ? (
                    <FontAwesomeIcon icon={faPlus} />
                  ) : (
                    <FontAwesomeIcon icon={faMinus} />
                  )}
                </span>{" "}
                Distance
              </div>
              <div className="form-groups" id="form-distance-group">
                <table
                  style={!distanceStatus ? { opacity: 0.4 } : { opacity: 1 }}
                >
                  <tbody>
                    <tr>
                      <td>Center coordinates</td>
                    </tr>
                    <tr>
                      <td>
                        X:{" "}
                        <input
                          type="text"
                          className="distance-center-values"
                          id="distance-center-values-x"
                          placeholder="e.g. 21.184096"
                          value={
                            CenterCoords.CenterX ? CenterCoords.CenterX : ""
                          }
                          onChange={(e) =>
                            (formProperties.Distance.CenterX = e.target.value)
                          }
                          disabled={!distanceStatus}
                        />
                      </td>
                      <td>
                        Y:{" "}
                        <input
                          type="text"
                          className="distance-center-values"
                          id="distance-center-values-y"
                          placeholder="e.g. 39.353138"
                          value={
                            CenterCoords.CenterY ? CenterCoords.CenterY : ""
                          }
                          onChange={(e) =>
                            (formProperties.Distance.CenterY = e.target.value)
                          }
                          disabled={!distanceStatus}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Distance (m):</td>

                      <td>
                        <input
                          type="text"
                          className="distance-rad-values"
                          id="distance-rad-values"
                          placeholder="e.g. 2.50"
                          value={
                            CenterCoords.Distance
                              ? (CenterCoords.Distance * 1000).toFixed(3)
                              : ""
                          }
                          onChange={(e) =>
                            (formProperties.Distance.DistValue = e.target.value)
                          }
                          disabled={!distanceStatus}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <button
                            className="form-btns"
                            id="draw-form-distance"
                            onClick={() => DrawCircle()}
                            disabled={!distanceStatus}
                            style={
                              !distanceStatus
                                ? { opacity: 0.4 }
                                : { opacity: 1 }
                            }
                          >
                            <FontAwesomeIcon icon={faDrawPolygon} /> Draw on Map
                          </button>
                          <button
                            className="form-btns"
                            id="clear-form-distance"
                            onClick={() => eraseCircle()}
                            disabled={!distanceStatus}
                            style={
                              !distanceStatus
                                ? { opacity: 0.4 }
                                : { opacity: 1 }
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <button id="form-submit-btn" onClick={() => checkReq(props)}>
            Submit
          </button>
        </div>
      </div>
      {BBOXsmallTab && (
        <div className="BBOX-small-tab">
          <div className="form-headers" id="form-bbox-header">
            Area BBOX
          </div>
          <div className="form-groups" id="form-bbox-group">
            <table>
              <tbody>
                <tr>
                  <td>Upper Corner</td>
                </tr>
                <tr>
                  <td>
                    X:{" "}
                    <input
                      type="text"
                      className="bbox-values"
                      id="bbox-values-upper-x"
                      placeholder="e.g. 21.184096"
                      value={BBOXcoords.UpperX ? BBOXcoords.UpperX : ""}
                      onChange={(e) =>
                        (formProperties.BBOX.UpperX = e.target.value)
                      }
                      disabled={!BBOXStatus}
                    />
                  </td>
                  <td>
                    &nbsp;Y:{" "}
                    <input
                      type="text"
                      className="bbox-values"
                      id="bbox-values-upper-y"
                      placeholder="e.g. 39.353138"
                      value={BBOXcoords.UpperY ? BBOXcoords.UpperY : ""}
                      onChange={(e) =>
                        (formProperties.BBOX.UpperY = e.target.value)
                      }
                      disabled={!BBOXStatus}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Lower Corner</td>
                </tr>
                <tr>
                  <td>
                    X:{" "}
                    <input
                      type="text"
                      className="bbox-values"
                      id="bbox-values-lower-x"
                      placeholder="e.g. 21.182196"
                      value={BBOXcoords.LowerX ? BBOXcoords.LowerX : ""}
                      onChange={(e) =>
                        (formProperties.BBOX.LowerX = e.target.value)
                      }
                      disabled={!BBOXStatus}
                    />
                  </td>
                  <td>
                    &nbsp;Y:{" "}
                    <input
                      type="text"
                      className="bbox-values"
                      id="bbox-values-lower-y"
                      placeholder="e.g. 39.423138"
                      value={BBOXcoords.LowerY ? BBOXcoords.LowerY : ""}
                      onChange={(e) =>
                        (formProperties.BBOX.LowerY = e.target.value)
                      }
                      disabled={!BBOXStatus}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {distancesmallTab && (
        <div className="Distance-small-tab">
          <div className="form-headers" id="form-distance-header">
            Distance
          </div>
          <div className="form-groups" id="form-distance-group">
            <table>
              <tbody>
                <tr>
                  <td>Center coordinates</td>
                </tr>
                <tr>
                  <td>
                    X:{" "}
                    <input
                      type="text"
                      className="distance-center-values"
                      id="distance-center-values-x"
                      placeholder="e.g. 21.184096"
                      value={CenterCoords.CenterX ? CenterCoords.CenterX : ""}
                      onChange={(e) =>
                        (formProperties.Distance.CenterX = e.target.value)
                      }
                      disabled={!distanceStatus}
                    />
                  </td>
                  <td>
                    Y:{" "}
                    <input
                      type="text"
                      className="distance-center-values"
                      id="distance-center-values-y"
                      placeholder="e.g. 39.353138"
                      value={CenterCoords.CenterY ? CenterCoords.CenterY : ""}
                      onChange={(e) =>
                        (formProperties.Distance.CenterY = e.target.value)
                      }
                      disabled={!distanceStatus}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Distance (m):</td>
                  <td>
                    <input
                      type="text"
                      className="distance-rad-values"
                      id="distance-rad-values"
                      placeholder="e.g. 2.50"
                      value={
                        CenterCoords.Distance
                          ? (CenterCoords.Distance * 1000).toFixed(3)
                          : ""
                      }
                      onChange={(e) =>
                        (formProperties.Distance.DistValue = e.target.value)
                      }
                      disabled={!distanceStatus}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {cursorBox.show && (
        <div
          className="cursor-box"
          style={{ top: cursorBox.y, left: cursorBox.x }}
        >
          {cursorBox.content}
        </div>
      )}
    </>
  );

  function checkReq(props) {
    //Site Check
    if (formProperties.Site === null) {
      alert("Please Select a Site first!");
      return;
    }
    //Observations Check
    if (formProperties.Properties.length === 0) {
      alert("Please Select an Observed Datasets first!");
      return;
    }
    // Time Period Check
    if (formProperties.BegDate === null) {
      alert("Please Select a Begin Date first!");
      return;
    }
    if (formProperties.EndDate === null) {
      alert("Please Select a End Date first!");
      return;
    }
    //Aggregation Check
    if (formProperties.Aggregation.Without === false) {
      if (
        formProperties.Aggregation.AggValue === null ||
        formProperties.Aggregation.AggValue === "" ||
        formProperties.Aggregation.AggValue === undefined ||
        isNaN(formProperties.Aggregation.AggValue)
      ) {
        alert(
          "Please Fill an Aggregation Value first. Only numbers are accepted"
        );
        return;
      }
      if (formProperties.Aggregation.AggDuration === null) {
        alert("Please Select an Aggregation Duration first!");
        return;
      }
      if (formProperties.Aggregation.AggFunction === null) {
        alert("Please Select an Aggregation Function first!");
        return;
      }
    }
    //Result Values
    if (formProperties.ResVal.Without === false) {
      let close = true;
      formProperties.ResVal.DatasetsList.forEach((dataset) => {
        if (isNaN(dataset.ResValue)) {
          alert("Please Fill an Result Value first. Only numbers are accepted");
          close = false;
        }
      });

      if (!close) return;
    }
    //BBOX
    if (formProperties.BBOX.Without === false) {
      if (
        formProperties.BBOX.UpperX === null ||
        formProperties.BBOX.UpperX === "" ||
        formProperties.BBOX.UpperX === undefined ||
        isNaN(formProperties.BBOX.UpperX) ||
        Number(formProperties.BBOX.UpperX) < 19 ||
        Number(formProperties.BBOX.UpperX) > 30
      ) {
        alert(
          "Area BBOX: Please fill properly the Upper Corner X field (EPSG 4326)."
        );
        return;
      }
      if (
        formProperties.BBOX.UpperY === null ||
        formProperties.BBOX.UpperY === "" ||
        formProperties.BBOX.UpperY === undefined ||
        isNaN(formProperties.BBOX.UpperY) ||
        Number(formProperties.BBOX.UpperY) < 34.5 ||
        Number(formProperties.BBOX.UpperY) > 41.5
      ) {
        alert(
          "Area BBOX: Please fill properly the Upper Corner Y field (EPSG 4326)."
        );
        return;
      }
      if (
        formProperties.BBOX.LowerX === null ||
        formProperties.BBOX.LowerX === "" ||
        formProperties.BBOX.LowerX === undefined ||
        isNaN(formProperties.BBOX.LowerX) ||
        Number(formProperties.BBOX.LowerX) < 19 ||
        Number(formProperties.BBOX.LowerX) > 30
      ) {
        alert(
          "Area BBOX: Please fill properly the Lower Corner X field (EPSG 4326)."
        );
        return;
      }
      if (
        formProperties.BBOX.LowerY === null ||
        formProperties.BBOX.LowerY === "" ||
        formProperties.BBOX.LowerY === undefined ||
        isNaN(formProperties.BBOX.LowerY) ||
        Number(formProperties.BBOX.LowerY) < 34.5 ||
        Number(formProperties.BBOX.LowerY) > 41.5
      ) {
        alert(
          "Area BBOX: Please fill properly the Lower Corner Y field (EPSG 4326)."
        );
        return;
      }
    }
    //Distance
    if (formProperties.Distance.Without === false) {
      if (
        formProperties.Distance.CenterX === null ||
        formProperties.Distance.CenterX === "" ||
        formProperties.Distance.CenterX === undefined ||
        isNaN(formProperties.Distance.CenterX) ||
        Number(formProperties.Distance.CenterX) < 19 ||
        Number(formProperties.Distance.CenterX) > 30
      ) {
        alert(
          "Area Distance: Please fill properly the Center X field (EPSG 4326)."
        );
        return;
      }
      if (
        formProperties.Distance.CenterY === null ||
        formProperties.Distance.CenterY === "" ||
        formProperties.Distance.CenterY === undefined ||
        isNaN(formProperties.Distance.CenterY) ||
        Number(formProperties.Distance.CenterY) < 34.5 ||
        Number(formProperties.Distance.CenterY) > 41.5
      ) {
        alert(
          "Area Distance: Please fill properly the Center Y field (EPSG 4326)."
        );
        return;
      }
      if (
        formProperties.Distance.DistValue === null ||
        formProperties.Distance.DistValue === "" ||
        formProperties.Distance.DistValue === undefined ||
        isNaN(formProperties.Distance.DistValue) ||
        Number(formProperties.Distance.DistValue) <= 0
      ) {
        alert("Area Distance: Please fill properly the Distance field (km).");
        return;
      }
    }

    let pointsdataset = false;

    formProperties.Properties.map((dataset) =>
      dataset.includes("point") ? (pointsdataset = true) : null
    );
    if (
      pointsdataset &&
      formProperties.BBOX.Without === true &&
      formProperties.Distance.Without === true
    ) {
      alert(
        "An area of interest should be determined in order to request these selected datasets.\nPlease use the Area BBOX or Distance filter to determine the area first."
      );
      return;
    }

    // Sent the Get Data Request
    formProperties.Properties.forEach((property) => {
      if (property !== "GNSS_E" && property !== "GNSS_h") {
        if (property === "GNSS_N") {
          property = "GNSS";
        }
        ExecuteReqURL(StructGetDataReqURL(property));
      }
    });

    // Refresh the map with for the new selected Site
    props.setAOI(formProperties.Site);
    // Hide main Form by clicking Submit button
    showHideSearchTab(false);
  }

  // Triggers the getDataReq function to fetch the data
  function ExecuteReqURL(url) {
    async function sendDataRequest() {
      let setLoadingReqPercentage = props.setLoadingReqPercentage
      const response = await getDataReq(url, setLoadingReqPercentage );
      handleData(response);
      props.setDataHandled(!props.dataHandled);
      setTimeout(() => {
        props.setLoadingReqPercentage(100)
      }, 1000);
    }
    sendDataRequest();
  }

  // For hiding and showing Data Search Tab
  function showHideSearchTab(show, smallTab) {
    setInitialTab(false);
    changeVisibility(show);
    // Deactivate distance and BBOX filters if it is active in submition
    setCursorBox({ show: false });
    if (!smallTab) {
      if (BBOXonMap) {
        drawBBOX = false;
        globalMap.removeLayer("BBOX-polygon");
        globalMap.removeLayer("BBOX-outline");
        globalMap.removeSource("BBOX-polygon");
        BBOXclicks = 0;
        BBOXonMap = false;
        formProperties.BBOX.Without = false;
      }
      if (CircleonMap) {
        drawCircle = false;
        globalMap.removeLayer("Circle-polygon");
        globalMap.removeLayer("Circle-outline");
        globalMap.removeSource("Circle-polygon");
        Circleclicks = 0;
        CircleonMap = false;
        formProperties.Distance.Without = false;
      }
    }
  }

  function StructGetDataReqURL(property) {
    const f = formProperties;
    // property = "sar_meas"; // only in dev mode
    let url = `?dataset=${property}&date=${f.BegDate},${f.EndDate}&site=${f.Site}`;

    // Add !if exist! Aggregation filter args to the req. URL
    if (!f.Aggregation.Without && f.Aggregation.AggValue !== 0) {
      url += `&Agg=${f.Aggregation.AggValue}:${f.Aggregation.AggDuration}:${f.Aggregation.AggFunction}`;
    }

    // Add !if exist! Result Values filter args to the req. URL
    if (!f.ResVal.Without) {
      for (let set of f.ResVal.DatasetsList) {
        // if the requested dataset is the GNSS, add the three different parms to the url.
        // The set.ResProperty === "GNSS_N" is used to achive the adding of GNSS parms only one time, when the GNSS_N is detetected.
        if (property === "GNSS" && set.ResProperty === "GNSS_N") {
          url += `&ResValues=`;
          f.ResVal.DatasetsList.forEach((p) => {
            //Identify the 3 GNSS params N, E, h
            if (
              p.ResProperty.includes("GNSS") &&
              p.ResValue !== null &&
              p.ResValue !== ""
            ) {
              url += `${p.ResProperty}:${p.ResCondition}:${p.ResValue}$`;
            }
          });
          url = url.slice(0, -1);
        } else if (!set.ResProperty.includes("GNSS")) {
          if (
            set.ResProperty === property &&
            set.ResValue !== null &&
            set.ResValue !== ""
          ) {
            url += `&ResValues=${set.ResProperty}:${set.ResCondition}:${set.ResValue}`;
          }
        }
      }
    }

    // Add !if exist! BBOX filter args to the req. URL
    if (!f.BBOX.Without) {
      url += `&BBOX=${f.BBOX.LowerX},${f.BBOX.LowerY}:${f.BBOX.UpperX},${f.BBOX.UpperY}`;
    }

    // Add !if exist! Distance filter args to the req. URL
    if (!f.Distance.Without) {
      url += `&Dwithin=${f.Distance.CenterX},${f.Distance.CenterY}:${f.Distance.DistValue}`;
    }
    return url;
  }
};

function changeVisibility(vis) {
  let formstyle = document.getElementById("main-form").style;
  let backformstyle = document.getElementById(
    "main-form-glass-background"
  ).style;
  if (vis) {
    formstyle.display = "block";
    backformstyle.display = "block";
  } else {
    formstyle.display = "none";
    backformstyle.display = "none";
  }
}

export { Form, changeVisibility };
