import { useState } from "react";
import { login } from "./Requests";
import logo from "./img/proion_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Icons
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";

const LoginPage = ({ setLoggedIn, setDisplayChecked }) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [failedLogin, setFailedLogin] = useState(false);
  const [failedRequest, setFailedRequest] = useState(false);
  const [userFocused, setUserFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent the default form submission behavior
      event.preventDefault();
      // Call your login function here
      login(
        username,
        password,
        setFailedLogin,
        setFailedRequest,
        setLoggedIn,
        setDisplayChecked
      );
    }
  };

  return (
    <>
      <div className="glass-background" id="login-glass-background" />
      <div id="loginPage">
        <div className="login-page-content">
          <div className="login-header">
            <img className="login-logo-image" src={logo} />
          </div>
          <div className="inputs-container">
            <div
              className={
                userFocused
                  ? "login-inputs-icons-focused"
                  : "login-inputs-icons"
              }
              id="username"
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
            <input
              type="text"
              placeholder="Username"
              className="login-inputs"
              value={username}
              onChange={(e) => {
                setUsername(e.currentTarget.value);
              }}
              onFocus={(e) => setUserFocused(true)}
              onBlur={(e) => setUserFocused(false)}
            />
          </div>
          <div className="inputs-container">
            <div
              className={
                passwordFocused
                  ? "login-inputs-icons-focused"
                  : "login-inputs-icons"
              }
              id="password"
            >
              <FontAwesomeIcon icon={faKey} />
            </div>
            <input
              type="password"
              placeholder="Password"
              className="login-inputs"
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              onFocus={(e) => setPasswordFocused(true)}
              onBlur={(e) => setPasswordFocused(false)}
              onKeyDown={handleKeyDown}
            />
          </div>
          {failedLogin && (
            <p className="wrong-login">Wrong username or password</p>
          )}
          {failedRequest && (
            <p className="wrong-login">
              An error has occured. Please try again later
            </p>
          )}
          <button
            type="button"
            value="Log in"
            id="log-in-btn"
            onClick={() => {
              login(
                username,
                password,
                setFailedLogin,
                setFailedRequest,
                setLoggedIn,
                setDisplayChecked
              );
            }}
          >
            Log In
          </button>
        </div>
      </div>
    </>
  );
};

const CheckedLogin = ({ setLoggedIn }) => {
  return (
    <>
      <div className="glass-background" id="login-glass-background" />
      <div id="loginPage">
        <div className="login-page-content">
          <div className="login-header">
            <img className="login-logo-image" src={logo} />
          </div>
          <div className="checked-wrapper">
            {" "}
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              {" "}
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />{" "}
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
export { LoginPage, CheckedLogin };
