import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Map } from "./Map";
import { Nav } from "./Nav";
import { Form } from "./Form.js";
import { Loading, SecondaryLoadind } from "./Loading";
import { getInitgetCapabilitiesReq, checkLogIn } from "./Requests";
import {CheckedLogin, LoginPage} from "./LoginPage";

function App() {
  const [loadingBarPercentage, setLoadingBarPercentage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState(false);
  const [getCapabilities, setGetCapabilities] = useState(null);
  const [initRequestSatus, setInitRequestSatus] = useState(false);
  const [dataHandled, setDataHandled] = useState(false);
  const [loadingReqPercentage, setLoadingReqPercentage] = useState(0);
  const [AOI, setAOI] = useState(null);
  const [loggedIn, setLoggedIn] = useState(true);
  const [displayChecked, setDisplayChecked] = useState(false)


  //useEffect(() => {
  //  checkLogIn(setLoggedIn, setDisplayChecked);
  //}, []);

  useEffect(() => {
    if (![false, null, undefined].includes(loggedIn)) {
      async function sendRequest() {
        const response = await getInitgetCapabilitiesReq();
        setGetCapabilities(response);
        setInitRequestSatus(true);
      }
      sendRequest();
    }
  }, [loggedIn]);

  return (
    <>
      {loggedIn && (loading || !initRequestSatus) && (
        <Loading percentage={loadingBarPercentage} setServices={setServices} />
      )}
      <Nav services={services} setServices={setServices} AOI={AOI} />
      {loggedIn && initRequestSatus && (
        <Form
          getCapabilities={getCapabilities}
          setDataHandled={setDataHandled}
          dataHandled={dataHandled}
          setLoadingReqPercentage={setLoadingReqPercentage}
          setAOI={setAOI}
        />
      )}
      {(!loggedIn && !initRequestSatus) || initRequestSatus ? (
        <Map
          setLoadingBarPercentage={setLoadingBarPercentage}
          setLoading={setLoading}
          dataHandled={dataHandled}
          getCapabilities={getCapabilities}
          loadingReqPercentage={loadingReqPercentage}
          setLoadingReqPercentage={setLoadingReqPercentage}
          AOI={AOI}
          loggedIn={loggedIn}
        />
      ) : null}
      <SecondaryLoadind loadingReqPercentage={loadingReqPercentage} />
      {!loggedIn && !displayChecked && <LoginPage setLoggedIn={setLoggedIn} setDisplayChecked={setDisplayChecked}/>}
      {!loggedIn && displayChecked && <CheckedLogin />}
    </>
  );
}

export default App;
