const About = ({ setAboutModalState }) => {
  return (
    <div className='about-modal'>
      <div className='modal'>
        <div className='modal-header'>
          <h5 id='aboutTitle' className='modal-title'>
            PROION
          </h5>
          <h4 className='modal-subtitle'>
            Multiparametric microsensor monitoring platform of the Enceladus
            Hellenic Supersite
          </h4>
          <button
            className='close-modal'
            type='button'
            onClick={() => setAboutModalState(false)}
          >
            ×
          </button>
        </div>
        <div id='aboutContents' className='modal-body'>
          <h3>About PROION Project</h3>
          <p>
            Based on the need for continuous monitoring of the environment and
            infrastructure in the tectonically and seismically active area of
            the Greek “SuperSite” (Corinthian, Ionian Islands, etc.) in the
            collaborative project PROION will be the development of three
            technological prototype products (with features that are implemented
            for the first time) as well as their verification /
            characterization:
          </p>

          <p>
            1. Development of multiparametric data processing algorithms using
            intelligent methods.
          </p>
          <p>
            2. InSAR reflector (with thermal expansion / contraction control)
          </p>
          <p>3. Electronic platform (e-platform) with code name PROION</p>
          <p>
            The platform develops a methodology for combined use in real-time
            and near real-time of measurements by MEMS 3-axis accelerometers,
            differential radar image interferometry, GNSS receivers (GPS) and
            Laser scanners, and spatial recording of results.
          </p>
          <p>
            A major part of the project will be the processing of information
            through intelligent data analysis methods. In recent years the
            methods of machine learning and deep learning data have advanced
            significantly in the field of data analysis. They have also enabled
            sciences, in which the processing of large volumes of historical
            data (eg in Geology) which is an integral part, to record rapid and
            important developments. However, data processing should not be
            isolated from the human factor but should be able to combine the
            knowledge of experts with the information that can be extracted from
            the data to create sophisticated and optimized decision-making
            tools. In this proposal, in order to be able to implement what has
            been briefly presented above, it is necessary to use new and
            advanced scientific theories. Such new theories are the methods of
            fuzzy cognitive networks (AGMs) which together with machine learning
            algorithms will be able to provide a modern tool for decision making
            and support for monitoring critical infrastructure.
          </p>
          <h3>Acknowledgements</h3>
          <p>
            The partners of the project are thankful to the European Ground
            Motion Service (EGMS) and Copernicus programme for the free
            distribution of PSI measurements.
          </p>
          <p>
            LiCSAR contains modified Copernicus Sentinel data [Year of data
            used] analysed by the Centre for the Observation and Modelling of
            Earthquakes, Volcanoes and Tectonics (COMET). LiCSAR uses JASMIN,
            the UK’s collaborative data analysis environment
            (http://jasmin.ac.uk)
          </p>
          {/* <h3>Contact Info</h3> */}
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='continue-btn'
            onClick={() => setAboutModalState(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
